<template>
	<div class="profile-dropdown">
		<div
			class="profile-item"
			v-if="institution"
			@click="showInstitutions = true"
		>
			<div class="profile-image">
				<img :src="institution.logo_url" />
			</div>
			<div class="profile-name">{{ institution.name }}</div>
		</div>

		<modal v-if="role <= 1" v-model="showInstitutions" size="medium">
			<institutions-view
				@switchedInstitution="closeShowInstitutions"
			></institutions-view>
		</modal>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

import Modal from '../ui/Modal'
import InstitutionsView from '../pages/Institutions/InstitutionsView'

export default {
	name: 'profile-dropdown',
	components: { Modal, InstitutionsView },
	props: {
		institution: Object
	},
	computed: {
		...mapGetters(['role'])
	},
	data() {
		return {
			showInstitutions: false
		}
	},
	methods: {
		closeShowInstitutions() {
			this.showInstitutions = false
		}
	}
}
</script>

<style lang="scss">
@import '../../styles/variables';

.profile-dropdown {
	display: block;
	position: relative;
	padding-bottom: 18px;
	.profile-item {
		display: flex;
		align-items: center;
		justify-content: left;
		.profile-image {
			background: #fff;
			width: 50px;
			height: 50px;
			border-radius: 10px;
			box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
			overflow: hidden;
			flex-shrink: 0;
			img {
				max-width: 100%;
				max-height: 100%;
			}
		}
		.profile-name {
			padding-left: 10px;
		}
	}
}
</style>
