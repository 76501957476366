<template>
	<div v-if="hasUser" class="dashboard">
		<sidebar></sidebar>
		<main class="content" id="content">
			<div class="topbar">
				<v-row style="padding-left: 24px; padding-right: 10px">
					<v-col style="flex: 4" class="ma-0 pa-0">
						<div
							v-if="false && !hasStripe"
							class="banner"
							v-bind:style="{ background: '#E25061' }"
							@click="stripeClicked"
						>
							<p>
								You must link a Stripe account before the end of
								the month.
								<br />
								We cannot process your donations without a
								linked Stripe account. Click to link.
							</p>
						</div>
						<div
							v-else-if="false && !hasBrand"
							class="banner"
							v-bind:style="{ background: '#E25061' }"
							@click="profileClicked"
						>
							<p>
								You must add your logo and cover image before
								donors can sign up to give. Click here to add.
							</p>
						</div>
						<div
							v-else-if="
								onPage('Brand') && onPage('home') && false
							"
							class="banner"
							@click="profileClicked"
						>
							<p>
								Did you know you had a custom donor registration
								page? Click to learn more.
							</p>
						</div>
					</v-col>
					<v-col
						align="right"
						style="align-items: flex-end"
						justify="center"
					>
						<v-menu offset-y style="margin: auto">
							<template v-slot:activator="{ on, attrs }">
								<v-icon
									v-bind="attrs"
									v-on="on"
									style="
										height: 32px;
										width: 32px;
										font-size: 32px;
									"
									class="profile-icon"
									>fas fa-user-circle</v-icon
								>
							</template>
							<v-list class="profile-list">
								<v-list-item style="width: 100%; display: flex">
									<v-list-item-title>
										<v-btn
											style="
												width: 100%;
												display: flex;
												justify-content: flex-start;
											"
											text
											@click="
												navigate({
													name: 'profile',
													query: { tab: 1 }
												})
											"
										>
											<div class="profile-info">
												<p class="name">
													{{ fullName }}
												</p>
												<p
													style="
														font-size: 0.875rem !important;
													"
												>
													{{ me.email }}
												</p>
											</div>
										</v-btn>
									</v-list-item-title>
								</v-list-item>
								<v-divider style="margin-top: 10px"></v-divider>
								<v-list-item
									v-for="(item, index) in userMenu"
									:key="index"
									style="width: 100%; display: flex"
								>
									<v-list-item-title>
										<v-btn
											style="
												width: 100%;
												display: flex;
												justify-content: flex-start;
												text-transform: none !important;
												letter-spacing: normal !important;
												font-weight: 400 !important;
											"
											@click="
												navigate({ name: item.path })
											"
											text
											>{{ item.title }}</v-btn
										></v-list-item-title
									>
								</v-list-item>
							</v-list>
						</v-menu>
					</v-col>
				</v-row>
			</div>
			<div class="maincontainer" id="maincontainer">
				<router-view v-if="hasUser"></router-view>
			</div>
		</main>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Sidebar from './Sidebar.vue'
import Modal from '../ui/Modal'
import UiButton from '../ui/Button'
import UiLabel from '../ui/Label'

export default {
	name: 'layout',
	components: {
		Sidebar,
		Modal,
		UiButton,
		UiLabel
	},
	data() {
		return {
			showModal: !this.$store.getters.hasStripe,
			showImageModal:
				(!this.$store.getters.hasImages ||
					!this.$store.getters.hasCoverImage) &&
				!window.location.href.includes('/Brand'),
			userMenu: [
				{ title: 'Billing Details', path: 'billing-details' },
				{ title: 'Logout', path: 'logout' }
			]
		}
	},
	computed: {
		...mapState({
			isLoggedIn: ({ me }) => me.isLoggedIn
		}),
		...mapGetters([
			'hasUser',
			'hasStripe',
			'institutionUuid',
			'hasBrand',
			'fullName',
			'me'
		])
	},
	methods: {
		clickMethod() {
			window.open('https://www.flourishchange.com', '_blank')
		},
		bannerClick(url) {
			window.open(url, '_blank')
		},
		stripeClicked() {
			window.location.href =
				process.env.VUE_APP_stripeUrl + '&state=' + this.institutionUuid
		},
		profileClicked() {
			this.$router.push({ name: 'brand' }).then(() => this.$router.go())
		},
		onPage(page) {
			return !window.location.href.includes(page)
		}
	}
}
</script>

<style lang="scss">
@import '~@roundupapp/component-library/src/styles/font';

.dashboard {
	width: 100%;
	display: flex;
	padding: 0px 0px 20px 225px;
	.content {
		width: 100%;
		flex-grow: 1;
		margin-top: 62.5px;
		padding-bottom: 50px !important;
		.topbar {
			position: fixed;
			background-color: #fff;
			top: 0px;
			left: 225px;
			right: 0px;
			height: 62.5px;
			border-bottom: 1px solid #dcdee3;
			z-index: 99;
		}
		.maincontainer {
			display: flex;
			padding: 0px;
		}
	}
	.banner {
		width: 100%;
		background: #184fd5;
		max-width: 1280px;
		padding: 3px 0px;
		margin: 16px 0px;
		border-radius: 14px;
		justify-content: center;
		text-align: center;
		cursor: pointer;
		p {
			font-size: 20px;
			margin: 0px;
			font-weight: 600;
			color: #fff;
		}
	}
	.red {
		background: #e25061;
	}
	.profile-icon {
		color: $roundup-slate;
	}
}

.v-btn {
	&:disabled {
		background: $roundup-light-grey;
	}
}

.profile-list {
	border-radius: 8px;
	padding-top: 0px;
	.name {
		font-weight: $font-medium;
	}
	.profile-info {
		flex-direction: column;
		justify-content: flex-start;
		align-items: start;
		padding: 0px;
		text-transform: none !important;
		p {
			text-align: left;
			text-transform: none !important;
			letter-spacing: normal !important;
		}
	}
}

.v-application {
	p {
		margin: 0px !important;
	}
}
</style>
