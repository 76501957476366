import axios from '../../network/axios'
import { analytics } from '../../utilities/tracker'
import {
	STRIPE_ACCOUNT_SETUP_REQUEST,
	STRIPE_ACCOUNT_SETUP_SUCCESS,
	STRIPE_EXPRESS_REQUEST,
	STRIPE_EXPRESS_SUCCESS,
	STRIPE_ACCOUNT_SETUP_FAILURE,
	USER_SET_FIRST_TIME_LOGGING_IN
} from '../types'

const state = {
	isFetching: false,
	success: false,
	url: null,
	finished: false,
	errors: null
}

const getters = {}

const actions = {
	getStripeExpressLink({ commit, getters }, request) {
		return new Promise((resolve, reject) => {
			analytics.track('funds_distribution_selected', {
				institution_id: getters.me.institution.id,
				institution_name: getters.me.institution.name,
				donation_method: 'stripe'
			})
			commit(STRIPE_EXPRESS_REQUEST)
			axios
				.get('/create_express_account', { params: request })
				.then(({ data }) => {
					analytics.track('funds_distribution_confirmed', {
						institution_id: getters.me.institution.id,
						institution_name: getters.me.institution.name,
						donation_method: 'stripe'
					})
					analytics.track('registration_successful', {
						institution_id: getters.me.institution.id,
						institution_name: getters.me.institution.name,
						donation_method: 'stripe'
					})
					commit(STRIPE_EXPRESS_SUCCESS, data)
					resolve()
				})
				.catch(({ reject }) => {
					analytics.track('funds_distribution_error', {
						institution_id: getters.me.institution.id,
						institution_name: getters.me.institution.name,
						donation_method: 'stripe',
						error: reject
					})
				})
		})
	},
	setPaperCheck({ commit, getters }, request) {
		return new Promise((resolve, reject) => {
			analytics.track('funds_distribution_selected', {
				institution_id: getters.me.institution.id,
				institution_name: getters.me.institution.name,
				donation_method: 'check'
			})
			commit(STRIPE_ACCOUNT_SETUP_REQUEST)
			axios
				.get('/paper_check', { params: request })
				.then(({ data }) => {
					analytics.track('funds_distribution_confirmed', {
						institution_id: getters.me.institution.id,
						institution_name: getters.me.institution.name,
						donation_method: 'check'
					})
					analytics.track('registration_successful', {
						institution_id: getters.me.institution.id,
						institution_name: getters.me.institution.name,
						donation_method: 'check'
					})
					commit(USER_SET_FIRST_TIME_LOGGING_IN, true, { root: true })
					commit(STRIPE_ACCOUNT_SETUP_SUCCESS, data)
					resolve()
				})

				.catch(({ reject }) => {
					analytics.track('funds_distribution_error', {
						institution_id: getters.me.institution.id,
						institution_name: getters.me.institution.name,
						donation_method: 'check',
						error: reject
					})

					commit(STRIPE_ACCOUNT_SETUP_FAILURE, reject)
				})
		})
	},
	setPaypalUsername({ commit, getters }, username) {
		return new Promise((resolve, reject) => {
			analytics.track('funds_distribution_selected', {
				institution_id: getters.me.institution.id,
				institution_name: getters.me.institution.name,
				donation_method: 'paypal'
			})
			commit(STRIPE_ACCOUNT_SETUP_REQUEST)
			axios
				.post('/paypal', { username })
				.then(({ data }) => {
					analytics.track('funds_distribution_confirmed', {
						institution_id: getters.me.institution.id,
						institution_name: getters.me.institution.name,
						donation_method: 'paypal'
					})
					analytics.track('registration_successful', {
						institution_id: getters.me.institution.id,
						institution_name: getters.me.institution.name,
						donation_method: 'paypal'
					})
					commit(USER_SET_FIRST_TIME_LOGGING_IN, true, { root: true })
					commit(STRIPE_ACCOUNT_SETUP_SUCCESS, data)
					resolve()
				})

				.catch(({ response }) => {
					analytics.track('funds_distribution_error', {
						institution_id: getters.me.institution.id,
						institution_name: getters.me.institution.name,
						donation_method: 'paypal',
						error: reject
					})

					console.log(response)

					commit(STRIPE_ACCOUNT_SETUP_FAILURE, reject)
				})
		})
	},
	getStripeAccountDetails({ commit }, request) {
		return new Promise((resolve, reject) => {
			commit(STRIPE_ACCOUNT_SETUP_REQUEST)
			axios
				.get('/confirm_stripe_account', { params: request })
				.then(({ data }) => {
					if (data.stripe_errors !== null) {
						commit(STRIPE_ACCOUNT_SETUP_FAILURE, data)
					} else {
						commit(USER_SET_FIRST_TIME_LOGGING_IN, true, {
							root: true
						})
						commit(STRIPE_ACCOUNT_SETUP_SUCCESS, data)
					}
					resolve()
				})
				.catch(({ reject }) => {
					commit(STRIPE_ACCOUNT_SETUP_FAILURE, reject)
				})
		})
	}
}

const mutations = {
	[STRIPE_EXPRESS_REQUEST](state) {
		state.isFetching = true
	},
	[STRIPE_EXPRESS_SUCCESS](state, response) {
		state.isFetching = false
		state.success = true
		state.url = response.url
	},
	[STRIPE_ACCOUNT_SETUP_REQUEST](state) {
		state.isFetching = true
	},
	[STRIPE_ACCOUNT_SETUP_SUCCESS](state, response) {
		state.isFetching = false
		state.success = true
		state.finished = response.stripe_setup
	},
	[STRIPE_ACCOUNT_SETUP_FAILURE](state, response) {
		state.isFetching = false
		state.success = false
		state.finished = false
		state.errors = response.stripe_errors ? response.stripe_errors : ''
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}
