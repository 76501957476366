<template>
	<section class="sidebar">
		<profile-dropdown :institution="institution"></profile-dropdown>
		<nav>
			<li>
				<router-link :to="{ name: 'home' }">
					<i class="fas fa-home"></i>
					<span>Home</span>
				</router-link>
			</li>
			<li>
				<router-link :to="{ name: 'donors' }">
					<i class="fas fa-users"></i>
					<span>Donors</span>
				</router-link>
				<ul v-if="merchants && merchants.length">
					<router-link :to="{ name: 'donors.merchants' }">
						<span>Merchants</span>
					</router-link>
				</ul>
			</li>
			<li>
				<router-link :to="{ name: 'reports.donations' }">
					<i class="fas fa-chart-column"></i>
					<span>Reports</span>
				</router-link>
				<ul>
					<router-link :to="{ name: 'reports.donations' }">
						<span>Donations</span>
					</router-link>
				</ul>
			</li>
			<li>
				<router-link :to="{ name: 'brand' }">
					<i class="far fa-lightbulb"></i>
					<span>Resources</span>
				</router-link>
				<ul>
					<router-link :to="{ name: 'brand' }">
						<span>Brand Profile</span>
					</router-link>
				</ul>
				<ul>
					<router-link :to="{ name: 'library' }">
						<span>Marketing Library</span>
					</router-link>
				</ul>
				<ul>
					<router-link :to="{ name: 'donation-form' }">
						<span>Donation Form</span>
					</router-link>
				</ul>
			</li>
			<li>
				<router-link :to="{ name: 'campaigns' }">
					<i class="far fa-flag"></i>
					<span>Campaigns</span>
				</router-link>
			</li>
			<li v-if="showTexting">
				<a @click.prevent="viewTexting">
					<i class="far fa-comments"></i>
					<span>Connect Texting </span>
				</a>
			</li>
			<li>
				<router-link :to="{ name: 'profile' }">
					<i class="fas fa-cog"></i>
					<span>Settings</span>
				</router-link>
			</li>
		</nav>
		<div class="roundup-logo"></div>
	</section>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import ProfileDropdown from './ProfileDropdown'

export default {
	name: 'sidebar',
	components: { ProfileDropdown },
	data() {
		return {
			windowHeight: window.innerHeight
		}
	},
	computed: {
		...mapState({
			current: ({ me }) => me.current,
			showMenu: ({ params }) => params.showMenu,
			schools: ({ params }) => params.schools,
			merchants: ({ merchantList }) => merchantList.items
		}),
		...mapGetters(['institution', 'onboardingStep']),
		showTexting() {
			return [582698, 546752, 821879].includes(this.institution.id)
		}
	},
	methods: {
		...mapActions(['getMerchants']),
		onResize() {
			this.windowHeight = window.innerHeight
		},
		nextUp() {
			return 'Step ' + (this.onboardingStep + 1)
		},
		viewMarketingMaterials() {
			window.analytics.track('view_marketing_materials')
			window.open('https://roundupapp.com/marketing', '_blank')
		},
		viewTexting() {
			window.analytics.track('view_texting')
			window.open('https://app.texting.roundupapp.com', '_blank')
		},
		route(name) {
			this.$router.push({ name: name })
		}
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.onResize)
	},
	mounted() {
		this.$nextTick(() => {
			window.addEventListener('resize', this.onResize)
		})
		this.getMerchants({})
	}
}
</script>

<style lang="scss">
@import '../../styles/variables';
.sidebar {
	background-color: $roundup-bone;
	width: 100%;
	position: fixed;
	top: 0px;
	left: 0px;
	width: 225px;
	height: 100%;
	padding: 20px;
	z-index: 99;
	nav {
		li {
			list-style: none;
			ul {
				margin-top: 0px;
				margin-bottom: 0px;
				margin-left: 5px;
				a {
					font-weight: 400;
				}
			}
		}
		a {
			color: $roundup-medium-grey;
			font-size: 14px;
			font-weight: bold;
			text-decoration: none;
			display: flex;
			align-items: center;
			padding: 5px 10px 5px 0px;
			border-radius: 8px;
			span {
				display: inline-block;
			}
			.icon {
				font-size: 42px;
				vertical-align: middle;
				height: 42px;
				margin-top: -7px;
				margin-left: -8px;
			}
			.far {
				margin: 10px 10px 10px 5px;
				font-size: 18px;
			}
			.fas {
				margin: 10px 10px 10px 5px;
				font-size: 18px;
			}
		}
	}
	.onboarding-progress {
		background: $roundup-light-blue;
		border-radius: 5px;
		width: 125%;
		border-top: solid 2px $roundup-light-grey;
		border-bottom: solid 2px $roundup-light-grey;
		padding: 5px;
		h4 {
			color: $roundup-navy;
			margin: 0px;
			margin-bottom: 5px;
		}
		.shell {
			height: 28px;
			width: 100%;
			border: 1px solid $roundup-navy;
			border-radius: 13px;
			background: #fff;
			padding: 3px;
		}
		.bar {
			background: linear-gradient(
				-90deg,
				$gradient1,
				$gradient2,
				$gradient3
			);
			height: 20px;
			width: 15px;
			border-radius: 9px;
			span {
				float: right;
				padding: 4px 5px;
				color: $roundup-navy;
				font-size: 0.7em;
			}
		}
	}
	.roundup-logo {
		position: fixed;
		bottom: 0px;
		background: url('../../assets/roundup_logo.svg');
		background-size: 137px 14px;
		background-repeat: no-repeat;
		background-position: center;
		width: 137px;
		height: 14px;
		margin: 0px 0px 20px;
	}
}
.sidebar nav a.router-link-active,
.sidebar nav a:focus,
.sidebar nav a:hover {
	color: $roundup-navy;
	cursor: pointer;
}

@media screen and (max-height: 650px) {
	nav {
		li {
			a {
				padding-top: 2px;
				padding-bottom: 2px;
			}
		}
	}
}
</style>
