<template>
	<transition name="modal">
		<div class="modal-mask" v-if="value" ref="modal">
			<div class="modal-mask-background" @click="close()"></div>
			<div class="modal-wrapper" :class="[size]">
				<div class="modal-container">
					<button
						role="button"
						class="modal-close"
						@click="close()"
						aria-label="Close"
					>
						<span class="el-icon-close"></span>
					</button>

					<div class="modal-body">
						<slot></slot>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
export default {
	name: 'modal',
	props: {
		size: String,
		value: {
			type: Boolean,
			required: true
		}
	},
	provide() {
		const modalOptions = {}
		Object.defineProperty(modalOptions, 'modalOpen', {
			enumerable: true,
			get: () => this.value,
			set: newValue => {
				this.close()
			}
		})
		return { modalOptions }
	},
	created() {
		if (this.value) {
			document.body.classList.add('modal-open')
			this.$refs.modal.focus()
		}
	},
	beforeDestroy() {
		document.body.classList.remove('modal-open')
	},
	methods: {
		close() {
			document.body.classList.remove('modal-open')
			this.$emit('input', false)
		}
	},
	watch: {
		value: function(newValue, oldValue) {
			if (oldValue !== newValue && newValue) {
				document.body.classList.add('modal-open')
			} else {
				document.body.classList.remove('modal-open')
			}
		}
	}
}
</script>

<style lang="scss">
@import '../../styles/variables';

.modal-open {
	overflow: auto;
}
.modal-mask {
	position: fixed;
	z-index: 999;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	align-items: center;
	justify-content: center;
	transition: opacity 0.2s ease;
}
.modal-mask-background {
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 0;
	left: 0px;
	top: 0px;
}
.modal-wrapper {
	position: relative;
	z-index: 10;
	max-height: 97%;
	border-radius: 20px;
	width: 100%;
	overflow-y: auto;
	box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.12);
	&.large {
		max-width: 960px;
		.modal-container {
			max-width: 960px;
			width: 100%;
			height: 100%;
			max-height: 90vh;
		}
	}
	&.small {
		.modal-container {
			max-width: 400px;
		}
	}
	&.medium {
		.modal-container {
			max-width: 720px;
		}
	}
}
.modal-container {
	max-width: 90vw;
	width: 100%;
	margin: 0px auto;
	background-color: #fff;
	border-radius: 20px;
	transition: all 0.2s ease;
	min-height: 100%;
	overflow-y: auto;
}
@media screen and (max-width: 480px) {
	.modal-container {
		max-height: 500px;
		height: 100%;
	}
}
.modal-wrapper {
	&.small {
		max-width: 400px;
	}
	&.medium {
		max-width: 720px;
	}
	&.large {
		max-width: 960px;
	}
}

.modal-close {
	background: #fff;
	position: absolute;
	top: 22px;
	right: 22px;
	padding: 2px 5px 3px;
	text-align: right;
	color: #333;
	cursor: pointer;
	content: 'Close';
	text-transform: uppercase;
	font-weight: 600;
	font-size: 14px;
	border-width: 0px;
	border-radius: 8px;
	z-index: 999;
	span {
		font-size: 16px;
		vertical-align: middle;
	}
	&:hover {
		background: $roundup-navy;
		color: $white;
	}
}
.modal-body {
	padding: 30px 60px 40px;
	h3 {
		padding: 5px 0px 5px 0px;
		margin: 2px 0px 15px 0px;
		font-size: 24px;
		color: $roundup-medium-grey;
		letter-spacing: 1px;
	}
	.el-form {
		padding-top: 0px;
	}
}
.modal-footer {
	text-align: right;
}
.modal-default-button {
	background: #fff;
	border: 1px solid #ddd;
	border-radius: 4px;
	padding: 5px 15px;
}
.modal-enter {
	opacity: 0;
}
.modal-leave-active {
	opacity: 0;
}
.modal-enter-active .modal-wrapper,
.modal-leave-active .modal-wrapper {
	overflow-y: visible;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}
</style>
