import axios from '../../network/axios'
import {
	INSTITUTION_LIST,
	INSTITUTION_LIST_REQUEST,
	INSTITUTION_SEARCH_DETAIL
} from '../types.js'

const state = {
	isFetching: false,
	items: [],
	total: 0,
	selectedOrg: {}
}

const getters = {}

// actions
const actions = {
	getInstitutions({ commit }, params) {
		return new Promise((resolve, reject) => {
			commit(INSTITUTION_LIST_REQUEST)
			axios
				.get(
					`${
						process.env.VUE_APP_rootUrl
					}/institutions/search?query=${encodeURI(params.query)}`
				)
				.then(({ data }) => {
					const res = data.hits.map(hit => hit.document) || []
					commit(INSTITUTION_LIST, res)
					resolve()
				})
				.catch(err => console.log(err.message))
		})
	},
	searchInstitutionByUUID({ commit }, uuid) {
		return new Promise((resolve, reject) => {
			commit(INSTITUTION_LIST_REQUEST)
			axios
				.get('/institutions/lookup/' + uuid)
				.then(({ data }) => {
					commit(INSTITUTION_SEARCH_DETAIL, data)
					resolve()
				})
				.catch(reject)
		})
	},
	searchInstitutionByString({ commit }, string) {
		return new Promise((resolve, reject) => {
			commit(INSTITUTION_LIST_REQUEST)
			axios
				.post('/institutions/search', { query: string })
				.then(({ data }) => {
					commit(INSTITUTION_LIST, data.hits)

					resolve()
				})
				.catch(reject)
		})
	}
}

const mutations = {
	[INSTITUTION_LIST_REQUEST](state) {
		state.isFetching = true
	},
	[INSTITUTION_LIST](state, response) {
		state.isFetching = false
		state.items = response
		state.total = response.length
	},
	[INSTITUTION_SEARCH_DETAIL](state, response) {
		state.isFetching = false
		state.selectedOrg = response
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}
