import axios from '@/network/axios'
import { get } from '@/utilities'
import {
	DONOR_DETAIL,
	DONOR_DETAIL_FETCHING,
	DONOR_DETAIL_SUCCESS
} from '../types.js'

const state = {
	isFetching: false,
	item: null,
	graph: null,
	donations: null,
	totalDonated: 0
}

// actions
const actions = {
	getDonor({ commit }, id) {
		let params = ''
		if (window.location.href.split('?').length > 1) {
			params = '?' + window.location.href.split('?')[1]
		}
		commit(DONOR_DETAIL_FETCHING)
		return new Promise((resolve, reject) => {
			axios
				.get('/donors/' + id + params)
				.then(({ data }) => {
					commit(DONOR_DETAIL, data)
					resolve()
				})
				.catch(reject)
		})
	},
	pauseDonor({ dispatch }, ui_id) {
		// commit(DONOR_DETAIL_FETCHING)
		return new Promise((resolve, reject) => {
			axios
				.put('/donor/pause/' + ui_id)
				.then(() => {
					// commit(DONOR_DETAIL_SUCCESS)
					dispatch('getDonors')
					resolve()
				})
				.catch(({ response }) => reject(response.data))
		})
	}
}

const mutations = {
	[DONOR_DETAIL_FETCHING](state) {
		state.isFetching = true
	},
	[DONOR_DETAIL](state, response) {
		state.isFetching = false
		state.item = response.result
		state.graph = get(response, 'graph', [])
		state.donations = get(response, 'donations', [])
		state.totalDonated = get(response, 'totalDonated', 0)
	}
}

export default {
	state,
	actions,
	mutations
}
