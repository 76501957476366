import '@fortawesome/fontawesome-free/css/all.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import Components from '@roundupapp/component-library'

Components.init({ env: process.env.VUE_APP_NODE_ENV })
const { VuetifyConfig } = Components

Vue.use(Vuetify)

export default new Vuetify(VuetifyConfig)
