import axios from '../../network/axios'

import { PROJECT_DETAIL } from '../types.js'

const state = {
	isFetching: false,
	item: []
}

const getters = {}

const actions = {
	updateProject({ commit, dispatch }, project) {
		return new Promise((resolve, reject) => {
			axios.put('/projects/', project).then(
				({ data }) => {
					window.analytics.track('project_updated')
					commit(PROJECT_DETAIL, data)
					dispatch('getProjects')
					resolve()
				},
				({ response }) => {
					reject(response.data)
				}
			)
		})
	},
	createProject({ commit, dispatch }, project) {
		return new Promise((resolve, reject) => {
			axios.post('/projects', project).then(
				({ data }) => {
					window.analytics.track('project_created')
					commit(PROJECT_DETAIL, data)
					dispatch('getProjects')
					resolve()
				},
				({ response }) => {
					reject(response.data)
				}
			)
		})
	},
	toggleArchive({ commit, dispatch }, project) {
		return new Promise((resolve, reject) => {
			axios.put('/projects/archive/' + project.id).then(
				({ data }) => {
					window.analytics.track('project_archived')
					commit(PROJECT_DETAIL, data)
					dispatch('getProjects')
					resolve()
				},
				({ response }) => {
					reject(response.data)
				}
			)
		})
	},
	deleteProject({ commit, dispatch }, project) {
		return new Promise((resolve, reject) => {
			axios.put('/projects/delete/' + project.id).then(
				({ data }) => {
					window.analytics.track('project_deleted')
					commit(PROJECT_DETAIL, data)
					dispatch('getProjects')
					resolve()
				},
				({ response }) => {
					reject(response.data)
				}
			)
		})
	}
}

const mutations = {
	[PROJECT_DETAIL](state, item) {
		state.item = item
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}
