<template>
	<v-card
		class="task"
		dark
		ref="task"
		:style="{ maxHeight: open ? 'none' : '69px' }"
		v-if="!dismissed"
		@click="open = !open"
	>
		<v-card-title>To Do</v-card-title>
		<div class="body" ref="taskBody">
			<!-- <v-checkbox
				style="flex: 1"
				v-model="task.completed"
				:label="label && `${label}`"
				:disabled="disabled"
			></v-checkbox> -->

			<p style="flex: 5">{{ task.title }}</p>
			<v-btn elevation="0" fab icon class="dropdown-arrow" small>
				<v-icon style="font-size: 20px !important">{{
					open ? 'fas fa-caret-up' : 'fas fa-caret-down'
				}}</v-icon>
			</v-btn>
		</div>
		<div
			class="description"
			:style="{
				width: descriptionWidth
			}"
			v-if="open"
		>
			<hr />
			<p>
				{{ task.description }}
			</p>
			<v-card-actions jusity="space-between"
				><Roundup-Button text @click="dismissTask">
					Dismiss</Roundup-Button
				>
				<v-spacer></v-spacer>
				<Roundup-Button color="white" @click="navgiateTaskLink"
					>{{ task.cta }}
				</Roundup-Button></v-card-actions
			>
		</div>
	</v-card>
</template>

<script>
export default {
	name: 'task',
	props: ['checked', 'label', 'task', 'disabled'],
	data() {
		return {
			open: false,
			dismissed: false
		}
	},
	computed: {
		descriptionWidth() {
			if (this.$refs.taskBody) {
				return `${this.$refs.taskBody.clientWidth}px`
			}
			return 'auto'
		},
		link() {
			return JSON.parse(this.task.internal_link)
		}
	},
	methods: {
		dismissTask() {
			this.dismissed = true
			let dismissedList = localStorage.dismissedList
			if (dismissedList) {
				dismissedList = JSON.parse(dismissedList)
				dismissedList.push(this.task.id)
			} else {
				dismissedList = [this.task.id]
			}
			localStorage.dismissedList = JSON.stringify(dismissedList)
		},
		navgiateTaskLink() {
			if (window.analytics) {
				window.analytics.track('nonprofit_todo', { ...this.task })
			}
			if (this.task.external_link) {
				window.open(this.task.external_link, '_blank')
			} else {
				this.$router.push(this.task.internal_link)
			}
		}
	}
}
</script>

<style lang="scss">
@import '@roundupapp/component-library/src/styles/font';

.task {
	min-height: 66px !important;
	min-width: 300px !important;
	width: auto !important;
	margin: 5px !important;
	background: $roundup-purple !important;
	&:hover {
		cursor: pointer;
	}
	.v-card__title {
		padding: 0px 10px !important;
		padding-bottom: 0px !important;
		color: rgba(255, 255, 255, 0.8) !important;
		text-transform: uppercase !important;
		font-size: 0.875rem !important;
	}
	.body {
		display: flex !important;
		flex-direction: row !important;
		justify-content: flex-start !important;
		align-items: center !important;
		color: white !important;
		padding: 10px !important;
		padding-top: 0px !important;
		padding-bottom: 0px !important;
		position: relative !important;
		padding-right: 50px !important;
		margin-bottom: 5px;
		p {
			color: white !important;
			line-height: 32px !important;
			align-self: flex-start !important;
			white-space: nowrap !important;
			font-weight: $font-medium !important;
		}
		.dropdown-arrow {
			color: white !important;
			line-height: 32px !important;
			margin-left: auto !important;
			align-self: flex-start !important;
			position: absolute;
			right: 0px;
			top: -5px;
			&:hover {
				cursor: pointer;
			}
		}
	}
	.description {
		padding: 10px !important;
		padding-top: 0px !important;
		p {
			color: white !important;
			margin-top: 10px !important;
		}
	}

	.theme--dark.v-input--selection-controls.v-input--is-disabled:not(.v-input--indeterminate)
		.v-icon {
		background: inherit !important;
		color: #fff !important;
	}

	.v-input--selection-controls {
		margin-top: 0px !important;
	}
}
</style>
