<template>
	<v-app>
		<div id="app" class="app">
			<RoundUpAlert
				class="nav-template-alert"
				:alertTimeout="10000000"
				:showAlert="showAlert"
				:alertColor="alertColor"
				:alertText="alertText"
			/>
			<layout v-if="showHomeLayout"></layout>
			<auth-layout v-else></auth-layout>
		</div>
	</v-app>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { RoundUpAlert } from './components/ui'

import Layout from './components/layout/Layout'
import AuthLayout from './components/layout/AuthLayout'
export default {
	name: 'app',
	components: {
		AuthLayout,
		Layout,
		RoundUpAlert
	},
	computed: {
		...mapState({
			isLoggedIn: ({ me }) => me.isLoggedIn
		}),
		...mapGetters([
			'hasUser',
			'approved',
			'role',
			'institutionHasFinishedOnboarding',
			'alertTimeout',
			'showAlert',
			'alertColor',
			'alertPosition',
			'alertText'
		]),
		showHomeLayout() {
			if (!this.hasUser) {
				return false
			}
			if (this.role === 1) {
				return true
			}
			return this.institutionHasFinishedOnboarding
		}
	}
}
</script>

<style lang="scss">
$color-pack: false;

@import './styles/main';
@import './styles/variables';
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bitter:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('//unpkg.com/element-ui@2.13.2/lib/theme-chalk/index.css');

* {
	box-sizing: border-box;
}
body,
html {
	margin: 0px;
	padding: 0px;
	font-family: objektiv-mk3, 'Avenir', Helvetica, Arial, sans-serif;
	@media screen and (max-width: 480px) {
		height: 100%;
		.swal2-shown {
			overflow-y: visible !important;
		}
	}
}

#app {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	display: flex;
	align-items: center;
	justify-content: center;
}

strong {
	font-weight: $font-medium !important;
}

.v-progress-circular--indeterminate:not(.v-progress-circular--visible) > svg,
.v-progress-circular--indeterminate:not(.v-progress-circular--visible)
	.v-progress-circular__overlay {
	animation-play-state: inherit !important;
}

.v-card {
	border-radius: 8px !important;
}

.v-card__title {
	font-weight: $font-medium !important;
	color: $roundup-charcoal !important;
	letter-spacing: normal !important;
	font-size: 1.5rem !important;
}

.el-table {
	::deep.cell {
		word-break: break-word;
	}
}

.el-pager {
	padding-left: 0px !important;
}

.el-table__header-wrapper tr,
.el-table__header-wrapper th {
	background: $roundup-bone !important;
	text-transform: uppercase;
	font-weight: $font-medium !important;
}
.el-table__header-wrapper tr .cell,
.el-table__header-wrapper th .cell {
	font-size: 12px;
	font-weight: $font-medium !important;
	word-break: break-word;
}

.el-table__header-wrapper {
	position: relative;
}
.el-table--enable-row-hover {
	.el-table__body {
		tr {
			td {
				transition: none;
				cursor: pointer;
			}
			&:hover {
				border-radius: 5px;
				overflow: hidden;
				td {
					&:first-child {
						border-top-left-radius: 10px;
						border-bottom-left-radius: 10px;
					}
					&:last-child {
						border-top-right-radius: 10px;
						border-bottom-right-radius: 10px;
					}
				}
			}
		}
	}
}

::v-deep .v-data-table {
	::v-deep .v-data-table-header {
		background-color: $roundup-bone !important;
		text-transform: uppercase !important;
	}
}

.filter-group-name {
	padding-top: 0.5rem !important;
	font-size: 0.75rem !important;
	color: $roundup-dark-grey;
}
.filter-option {
	&:hover {
		background-color: $roundup-bone !important;
		cursor: pointer !important;
	}
}
.v-tooltip {
	z-index: 99 !important;
}
.v-tooltip__content {
	opacity: 1 !important;
}

.date-filter-picker {
	border-color: #747a8d !important;
	font-size: 16x !important;

	i {
		color: #747a8d !important;
		font-size: 0.875rem !important;
	}
}
</style>
