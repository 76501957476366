<template>
	<section class="institutions-table">
		<ui-label>
			<ui-input
				type="text"
				:autofocus="true"
				:autocorrect="false"
				v-model="params.query"
				placeholder="Search For Nonprofit"
			/>
		</ui-label>
		<el-table
			:data="items"
			style="width: 100%"
			@row-click="switchInstitutionMethod"
			empty-text="No Nonprofits"
		>
			<el-table-column prop="name" label="Name"> </el-table-column>
			<el-table-column label="Profile">
				<template slot-scope="scope">
					<span style="margin-left: 10px">{{
						scope.row.unclaimed_profile ? 'Unclaimed' : 'Claimed'
					}}</span>
				</template>
			</el-table-column>
		</el-table>

		<el-pagination
			@current-change="pageChange"
			:page-size="10"
			layout="prev, pager, next, total"
			:total="total"
		>
		</el-pagination>
	</section>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import UiInput from '../../ui/Input'
import UiLabel from '../../ui/Label'

export default {
	name: 'institutions-table',
	components: { UiInput, UiLabel },
	computed: {
		...mapState({
			items: ({ institutionList }) => institutionList.items,
			total: ({ institutionList }) => institutionList.total
		})
	},
	data() {
		return {
			params: {
				page: 1,
				perPage: 10,
				query: ''
			}
		}
	},
	methods: {
		...mapActions(['getInstitutions', 'switchInstitution']),
		pageChange(page) {
			this.params.page = page
			this.getInstitutions(this.params)
		},
		switchInstitutionMethod(item) {
			this.switchInstitution(item.id).then(() => {
				setTimeout(() => {
					this.$emit('switchedInstitution')
					this.$root.$emit('switchedInstitution')
				}, 2000)
			})
		}
	},
	mounted() {
		this.getInstitutions()
	},

	watch: {
		'params.query': function(newValue) {
			this.getInstitutions(this.params)
		}
	}
}
</script>

<style lang="scss"></style>
