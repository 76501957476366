// import Vue from 'vue'
export const identify = function(user) {
	const fullName = user.first_name + ' ' + user.last_name

	window.mixpanel.identify(user.id + '')
	window.mixpanel.people.set({
		$name: fullName,
		$email: user.email
	})
}

export const facebook = {
	boot(code) {
		window.fbq('init', code)
	},
	track(event) {
		window.fbq('track', event)
	}
}

export const analytics = {
	track(event, params = {}) {
		try {
			if (window.analytics) {
				params.source = 'web'
				window.analytics.track(event, params)
			}
		} catch (e) {
			console.log(e)
		}
	},
	identify(userID) {
		try {
			if (window.analytics) {
				window.analytics.identify(userID)
			}
		} catch (e) {
			console.log(e)
		}
	}
}
