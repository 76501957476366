import axios from '../../network/axios'

import { USER_DETAIL } from '../types.js'

const state = {
	isFetching: false,
	item: []
}

const getters = {}

const actions = {
	updateUser({ commit, dispatch }, user) {
		return new Promise((resolve, reject) => {
			axios.put('/users/' + user.id, user).then(
				({ data }) => {
					commit(USER_DETAIL, data)
					dispatch('getUsers')
					resolve()
				},
				({ response }) => {
					reject(response.data)
				}
			)
		})
	},
	createUser({ commit, dispatch }, user) {
		return new Promise((resolve, reject) => {
			axios.post('/users', user).then(
				({ data }) => {
					commit(USER_DETAIL, data)
					dispatch('getUsers')
					resolve()
				},
				({ response }) => {
					reject(response.data)
				}
			)
		})
	},
	removeUser({ commit, dispatch }, user) {
		return new Promise((resolve, reject) => {
			axios.delete('/users/' + user.id).then(
				({ data }) => {
					commit(USER_DETAIL, data)
					dispatch('getUsers')
					resolve()
				},
				({ response }) => {
					reject(response.data)
				}
			)
		})
	},
	resendInvite({ commit, dispatch }, user) {
		return new Promise((resolve, reject) => {
			axios.post('/users/resend', user).then(
				({ data }) => {
					commit(USER_DETAIL, data)
					dispatch('getUsers')
					resolve()
				},
				({ response }) => {
					reject(response.data)
				}
			)
		})
	}
}

const mutations = {
	[USER_DETAIL](state, item) {
		state.item = item
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}
