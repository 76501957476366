// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/roundup_logo.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".top-nav-onboarding{z-index:100;-webkit-box-shadow:0 10px 20px rgba(0,0,0,.1);box-shadow:0 10px 20px rgba(0,0,0,.1);background:#fff!important;width:100%;position:fixed;top:0;height:80px;left:0;margin-bottom:50px}.top-nav-onboarding .roundup-logo{position:relative;margin-top:25px;margin-left:auto;margin-right:auto;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:226px 29px;background-repeat:no-repeat;background-position:50%;width:226px;height:29px;opacity:1}", ""]);
// Exports
module.exports = exports;
