<template>
	<div class="roundup-input">
		<textarea
			@focus="resize"
			ref="textarea"
			:rows="rows"
			placeholder=" "
			v-bind:value="value"
			v-on:input="$emit('input', $event.target.value)"
			v-if="type == 'textarea'"
			:style="{ fontFamily: 'inherit' }"
		></textarea>
		<input
			v-else
			:type="type"
			ref="input"
			placeholder=" "
			:maxlength="maxlength"
			:minlength="minlength"
			:disabled="disabled"
			:autocomplete="autocorrect ? 'on' : 'off'"
			:autocorrect="autocorrect ? 'on' : 'off'"
			:autocapitalize="autocorrect ? 'on' : 'off'"
			:spellcheck="autocorrect"
			v-bind:value="value"
			v-on:input="$emit('input', $event.target.value)"
		/>
		<span class="roundup-float-label">{{ placeholder }}</span>
		<div
			class="roundup-input-counter"
			:style="{ color: 'red' }"
			v-if="minlength && value.length < minlength"
		>
			min. {{ minlength }} characters
		</div>
		<div class="roundup-input-counter " v-else-if="maxlength">
			{{ value ? value.length : 0 }}/{{ maxlength }}
		</div>
	</div>
</template>

<script>
export default {
	name: 'roundup-input',
	props: {
		value: {
			type: String,
			default: ''
		},
		rows: {
			type: Number,
			default: 1
		},
		maxlength: {
			type: Number,
			default: null
		},
		minlength: {
			type: Number,
			default: null
		},
		disabled: {
			type: Boolean,
			default: false
		},
		autosize: {
			type: Boolean,
			default: true
		},
		autocorrect: {
			type: Boolean,
			default: true
		},
		autofocus: {
			type: Boolean,
			default: false
		},
		type: String,
		placeholder: '',
		minHeight: {
			type: Number,
			default: 20
		}
	},
	computed: {
		computedStyles() {
			let objStyles = {}
			if (this.autosize) {
				objStyles.resize = 'none'
			}
			return objStyles
		}
	},
	methods: {
		resize: function() {
			if (this.type !== 'textarea') return
			this.$refs['textarea'].style.setProperty('height', 'auto')
			let contentHeight = this.$refs['textarea'].scrollHeight + 1
			if (this.minHeight) {
				contentHeight =
					contentHeight < this.minHeight
						? this.minHeight
						: contentHeight
			}
			const heightVal = contentHeight + 'px'
			this.$refs['textarea'].style.setProperty('height', heightVal)
			return this
		}
	},
	mounted() {
		if (this.type === 'textarea') this.resize()
		if (this.autofocus) this.$refs['input'].focus()
	},
	watch: {
		value() {
			this.$nextTick(this.resize)
		}
	}
}
</script>

<style lang="scss" scoped>
@import '../../styles/variables';

.roundup-input {
	& > span {
		position: absolute;
		bottom: 0em;
		left: 0em;
		cursor: text;
		font-size: 75%;
		opacity: 1;
		transition: all 0.2s;
		color: $roundup-medium-grey;
	}
	.roundup-input-counter {
		position: absolute;
		right: 0px;
		bottom: -5px;
		color: $roundup-dark-grey;
		border-radius: 10px;
		padding: 3px 7px;
		font-size: 65%;
	}
}
.roundup-input input,
.roundup-input textarea {
	color: $roundup-navy;
	border-width: 0px;
	border-bottom: 1px solid $roundup-light-grey;
	width: 100%;
	font-size: $font-size;
	padding: 10px 0px;
	outline: none;
	-webkit-appearance: none;
	border-radius: 0px;
	padding-top: 1em;
}
.roundup-input input:hover,
.roundup-input textarea:hover,
.roundup-input input:focus,
.roundup-input textarea:focus {
	border-bottom-color: $roundup-navy;
	outline: none;
}
.roundup-input input::-webkit-input-placeholder,
.roundup-input textarea::-webkit-input-placeholder,
.roundup-input input::-ms-input-placeholder,
.roundup-input textarea::-ms-input-placeholder {
	color: $roundup-navy;
}
.roundup-input input::placeholder,
.roundup-input textarea::placeholder {
	opacity: 1;
	transition: all 0.2s;
}
.roundup-input input:placeholder-shown:not(:focus)::placeholder,
.roundup-input textarea:placeholder-shown:not(:focus)::placeholder {
	opacity: 0;
}
.roundup-input input:placeholder-shown:not(:focus) + *,
.roundup-input textarea:placeholder-shown:not(:focus) + * {
	font-size: 100%;
	color: $roundup-slate;
	bottom: 1.6em;
}
.roundup-input input:placeholder-shown:focus + *,
.roundup-input textarea:placeholder-shown:focus + * {
	color: $roundup-navy;
}
.roundup-input input:disabled,
.roundup-input textarea:disabled {
	background: $roundup-lightest-grey;
	color: $roundup-slate;
}
.roundup-input input:disabled:hover,
.roundup-input textarea:disabled:hover {
	border-bottom-color: $roundup-light-grey;
}
</style>
