var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.hasUser)?_c('div',{staticClass:"dashboard"},[_c('sidebar'),_c('main',{staticClass:"content",attrs:{"id":"content"}},[_c('div',{staticClass:"topbar"},[_c('v-row',{staticStyle:{"padding-left":"24px","padding-right":"10px"}},[_c('v-col',{staticClass:"ma-0 pa-0",staticStyle:{"flex":"4"}},[(false && !_vm.hasStripe)?_c('div',{staticClass:"banner",style:({ background: '#E25061' }),on:{"click":_vm.stripeClicked}},[_c('p',[_vm._v(" You must link a Stripe account before the end of the month. "),_c('br'),_vm._v(" We cannot process your donations without a linked Stripe account. Click to link. ")])]):(false && !_vm.hasBrand)?_c('div',{staticClass:"banner",style:({ background: '#E25061' }),on:{"click":_vm.profileClicked}},[_c('p',[_vm._v(" You must add your logo and cover image before donors can sign up to give. Click here to add. ")])]):(
							_vm.onPage('Brand') && _vm.onPage('home') && false
						)?_c('div',{staticClass:"banner",on:{"click":_vm.profileClicked}},[_c('p',[_vm._v(" Did you know you had a custom donor registration page? Click to learn more. ")])]):_vm._e()]),_c('v-col',{staticStyle:{"align-items":"flex-end"},attrs:{"align":"right","justify":"center"}},[_c('v-menu',{staticStyle:{"margin":"auto"},attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
						var on = ref.on;
						var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"profile-icon",staticStyle:{"height":"32px","width":"32px","font-size":"32px"}},'v-icon',attrs,false),on),[_vm._v("fas fa-user-circle")])]}}],null,false,909176512)},[_c('v-list',{staticClass:"profile-list"},[_c('v-list-item',{staticStyle:{"width":"100%","display":"flex"}},[_c('v-list-item-title',[_c('v-btn',{staticStyle:{"width":"100%","display":"flex","justify-content":"flex-start"},attrs:{"text":""},on:{"click":function($event){return _vm.navigate({
												name: 'profile',
												query: { tab: 1 }
											})}}},[_c('div',{staticClass:"profile-info"},[_c('p',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.fullName)+" ")]),_c('p',{staticStyle:{"font-size":"0.875rem !important"}},[_vm._v(" "+_vm._s(_vm.me.email)+" ")])])])],1)],1),_c('v-divider',{staticStyle:{"margin-top":"10px"}}),_vm._l((_vm.userMenu),function(item,index){return _c('v-list-item',{key:index,staticStyle:{"width":"100%","display":"flex"}},[_c('v-list-item-title',[_c('v-btn',{staticStyle:{"width":"100%","display":"flex","justify-content":"flex-start","text-transform":"none !important","letter-spacing":"normal !important","font-weight":"400 !important"},attrs:{"text":""},on:{"click":function($event){return _vm.navigate({ name: item.path })}}},[_vm._v(_vm._s(item.title))])],1)],1)})],2)],1)],1)],1)],1),_c('div',{staticClass:"maincontainer",attrs:{"id":"maincontainer"}},[(_vm.hasUser)?_c('router-view'):_vm._e()],1)])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }