<template>
	<label class="roundup-label">
		<slot></slot>
	</label>
</template>

<script>
export default {
	name: 'roundup-label'
}
</script>

<style lang="scss">
@import '../../styles/variables';

.roundup-label {
	display: block;
	position: relative;
	padding-bottom: 18px;
}
</style>
