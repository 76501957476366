<template>
	<div class="line-graph">
		<canvas :ref="id"></canvas>
	</div>
</template>

<script>
import Chart from 'chart.js'
export default {
	name: 'bar-graph',
	props: {
		chartData: {
			type: Array,
			default: function() {
				return []
			}
		},
		append: {
			type: String,
			default: ''
		},
		prepend: {
			type: String,
			default: ''
		},
		labelKey: {
			type: String,
			default: 'label'
		},
		valueKey: {
			type: String,
			default: 'value'
		},
		title: {
			type: String,
			default: null
		},
		YUnit: {
			type: String,
			default: ''
		},
		backgroundColor: {
			type: Array,
			default: () => []
		},
		empty: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		hasBackground() {
			return this.hasBackground.length > 0
		},
		dataset() {
			let data = this.chartData
			if (data.length === 0) {
				return {
					labels: ['Donors', 'Merchants'],
					datasets: [
						{
							data: [10432, 8211],
							borderWidth: 1,
							lineTension: 0,
							pointBorderColor: '#2553CD',
							pointBackgroundColor: '#2553CD',
							backgroundColor: 'rgba(220, 222, 227, .5)'
						}
					]
				}
			}
			return {
				labels: data.map(item => item[this.labelKey]),
				datasets: [
					{
						data: data.map(item => item[this.valueKey]),
						borderColor:
							this.backgroundColor.length > 0
								? 'inherit'
								: '#2553CD',
						borderWidth: 1,
						lineTension: 0,
						pointBorderColor: '#2553CD',
						pointBackgroundColor: '#2553CD',
						fill: this.backgroundColor.length > 0,
						backgroundColor: !this.empty && this.backgroundColor
					}
				]
			}
		}
	},
	data() {
		return {
			chart: null,
			id: 'bar-chart' + (Math.random() + 1).toString(36).substring(7),
			options: {
				scales: {
					yAxes: [
						{
							ticks: {
								// Include a dollar sign in the ticks
								callback: function(value, index, values) {
									return (
										'$' +
										value
											.toString()
											.replace(
												/\B(?=(\d{3})+(?!\d))/g,
												','
											)
									)
								},
								suggestedMin: 0
							}
						}
					]
				},
				responsive: true,
				maintainAspectRatio: false,
				legend: {
					display: false
				},
				tooltips: {
					callbacks: {
						label: (tooltipItem, data) => {
							return (
								'$' +
								// eslint-disable-next-line
								data['datasets'][0]['data'][
									tooltipItem['index']
								]
									.toString()
									.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
							)
						}
					}
				}
			}
		}
	},
	methods: {
		reload() {
			this.chart.destroy()
			this.chart = new Chart(this.$refs[this.id], {
				type: 'bar',
				data: this.dataset,
				options: this.options
			})
		}
	},
	mounted() {
		this.chart = new Chart(this.$refs[this.id], {
			type: 'bar',
			data: this.dataset,
			options: this.options
		})
	},
	watch: {
		dataset() {
			this.reload()
		}
	}
}
</script>

<style lang="scss">
.line-graph {
	padding: 10px 20px;
	height: 300px;
}
</style>
