// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/roundup_logo.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".sidebar{background-color:#f5f6f8;width:100%;position:fixed;top:0;left:0;width:225px;height:100%;padding:20px;z-index:99}.sidebar nav li{list-style:none}.sidebar nav li ul{margin-top:0;margin-bottom:0;margin-left:5px}.sidebar nav li ul a{font-weight:400}.sidebar nav a{color:#71788c;font-size:14px;font-weight:700;text-decoration:none;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding:5px 10px 5px 0;border-radius:8px}.sidebar nav a span{display:inline-block}.sidebar nav a .icon{font-size:42px;vertical-align:middle;height:42px;margin-top:-7px;margin-left:-8px}.sidebar nav a .far,.sidebar nav a .fas{margin:10px 10px 10px 5px;font-size:18px}.sidebar .onboarding-progress{background:rgba(76,125,255,.1);border-radius:5px;width:125%;border-top:2px solid #e3e8ef;border-bottom:2px solid #e3e8ef;padding:5px}.sidebar .onboarding-progress h4{color:#2553cd;margin:0;margin-bottom:5px}.sidebar .onboarding-progress .shell{height:28px;width:100%;border:1px solid #2553cd;border-radius:13px;background:#fff;padding:3px}.sidebar .onboarding-progress .bar{background:-webkit-gradient(linear,right top,left top,from(#d3f9fb),color-stop(#d9f8ed),to(#fff6dd));background:linear-gradient(-90deg,#d3f9fb,#d9f8ed,#fff6dd);height:20px;width:15px;border-radius:9px}.sidebar .onboarding-progress .bar span{float:right;padding:4px 5px;color:#2553cd;font-size:.7em}.sidebar .roundup-logo{position:fixed;bottom:0;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:137px 14px;background-repeat:no-repeat;background-position:50%;width:137px;height:14px;margin:0 0 20px}.sidebar nav a.router-link-active,.sidebar nav a:focus,.sidebar nav a:hover{color:#2553cd;cursor:pointer}@media screen and (max-height:650px){nav li a{padding-top:2px;padding-bottom:2px}}", ""]);
// Exports
module.exports = exports;
