import axios from '../../network/axios'
import {
	RECEIPT_LIST_REQUEST,
	RECEIPT_LIST_SUCCESS,
	RECEIPT_LIST_FAILED
} from '../types.js'

const state = {
	isFetching: false,
	items: [],
	total: 0,
	error: null
}

const getters = {}

// actions
const actions = {
	getReceipts({ commit }) {
		return new Promise((resolve, reject) => {
			commit(RECEIPT_LIST_REQUEST)
			axios
				.get('/receipts')
				.then(({ data }) => {
					commit(RECEIPT_LIST_SUCCESS, data)
					resolve()
				})
				.catch(({ response }) => {
					commit(RECEIPT_LIST_FAILED, response.data)
					reject(response.data)
				})
		})
	},
	requestReceiptPDF({ commit }, date) {
		return new Promise((resolve, reject) => {
			commit(RECEIPT_LIST_REQUEST)
			axios
				.put('/receipt/pdf', { date })
				.then(({ data }) => {
					commit(RECEIPT_LIST_SUCCESS)
					resolve(data)
				})
				.catch(({ response }) => {
					commit(RECEIPT_LIST_FAILED, response.data)
					reject(response.data)
				})
		})
	}
}

const mutations = {
	[RECEIPT_LIST_REQUEST](state) {
		state.isFetching = true
		state.error = null
	},
	[RECEIPT_LIST_SUCCESS](state, response) {
		state.isFetching = false
		if (response) {
			state.items = response
			state.total = response.length
		}
	},
	[RECEIPT_LIST_FAILED](state, error) {
		state.isFetching = false
		state.error = error
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}
