<template>
	<div class="onboarding-footer">
		© {{ getYear }} Flourish Change, Inc. All rights reserved.
	</div>
</template>

<script>
export default {
	name: 'onboarding-footer',
	computed: {
		getYear() {
			return new Date().getFullYear()
		}
	}
}
</script>

<style lang="scss">
@import '../../styles/variables';
.onboarding-footer {
	margin-top: 10px;
	margin-bottom: 20px;
	text-align: center;
	color: #484a50;
	font-size: 14px;
}
</style>
