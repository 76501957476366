import axios from '../../network/axios'
import { get } from '../../utilities'

import {
	REPORT_LIST,
	REPORT_LIST_REQUEST,
	REPORT_TYPE_SET,
	REPORT_GENERAL,
	REPORT_ROI_SUCCESS,
	REPORT_ROI_FETCHING,
	REPORT_ROI_FAIL
} from '../types.js'

const state = {
	isFetching: false,
	items: [],
	total: 0,
	graph: null,
	report: null,
	general: {
		pendingDeposit: 0,
		donatedYTD: 0,
		newDonors: 0,
		totalDonors: 0
	},
	roi: {
		isLoading: false,
		error: null
	}
}

const getters = {
	hasTenDonors: state => state.general.totalDonors > 10
}

// actions
const actions = {
	getGeneralReport({ commit }) {
		return new Promise((resolve, reject) => {
			axios
				.get('/reports')
				.then(({ data }) => {
					commit(REPORT_GENERAL, data)
					resolve()
				})
				.catch(reject)
		})
	},

	getNewDonorsReport({ commit }, request) {
		return new Promise((resolve, reject) => {
			commit(REPORT_TYPE_SET, 'new-donors')
			commit(REPORT_LIST_REQUEST)
			axios
				.get('/reports/new-donors', { params: request })
				.then(({ data }) => {
					commit(REPORT_LIST, data)
					resolve()
				})
				.catch(reject)
		})
	},

	getDonationsReport({ commit }, request) {
		return new Promise((resolve, reject) => {
			commit(REPORT_TYPE_SET, 'donations')
			commit(REPORT_LIST_REQUEST)
			axios
				.get('/reports/donations', { params: request })
				.then(({ data }) => {
					commit(REPORT_LIST, data)
					resolve()
				})
				.catch(reject)
		})
	},

	getProfileReport({ commit }, uuid) {
		const url =
			'https://www.googleapis.com/analytics/v3/data/ga?ids=ga%3A177138831&start-date=30daysAgo&end-date=yesterday&metrics=ga%3Ausers&dimensions=ga%3AlandingPagePath%2Cga%3Adate&sort=ga%3Adate&filters=ga%3AlandingPagePath%3D%3D%2FRegister%2F3' +
			uuid +
			'&segment=gaid%3A%3A2u98iP6SRQ6KnCjOwU0PDg&access_token=ya29.GlsEBr1-OMHOVURy9YsFywuoR0p0yL243t4IlzQohVVqMImOzAAkoSBBtivfw_xQ9nqOwIdHdSZkaKP0ZkCIAqW-DEtYPtae49wICWCc6G0F-kHeFcUeAvBSS8_V'
		return new Promise((resolve, reject) => {
			commit(REPORT_TYPE_SET, 'profile')
			commit(REPORT_LIST_REQUEST)
			axios
				.get(url)
				.then(({ data }) => {
					commit(REPORT_LIST, data)
					resolve()
				})
				.catch(reject)
		})
	},
	getROIReport({ commit }) {
		return new Promise((resolve, reject) => {
			commit(REPORT_ROI_FETCHING)
			axios
				.get('/reports/roi')
				.then(({ data }) => {
					commit(REPORT_ROI_SUCCESS, data)
				})
				.catch(error => {
					commit(REPORT_ROI_FAIL, error)
				})
		})
	}
}

const mutations = {
	[REPORT_LIST_REQUEST](state) {
		state.isFetching = true
	},
	[REPORT_LIST](state, response) {
		state.isFetching = false
		state.items = response.results
		state.total = response.total
	},
	[REPORT_TYPE_SET](state, type) {
		if (state.report !== type) {
			state.report = type
			state.items = []
			state.total = 0
			state.graph = null
		}
	},
	[REPORT_GENERAL](state, data) {
		state.general = data
	},
	[REPORT_ROI_FETCHING](state) {
		state.roi.isLoading = true
	},
	[REPORT_ROI_SUCCESS](state, data) {
		state.roi = { isLoading: false, ...data }
	},
	[REPORT_ROI_FAIL](state, err) {
		state.roi = { isLoading: false, error: err }
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}
