import axios from '../../network/axios'
import { get } from '@/utilities'
import { analytics } from '@/utilities/tracker'

import {
	LOGIN,
	LOGOUT,
	USER_SET_CURRENT,
	USER_REMOVE_CURRENT,
	USER_SUCCESS_STEP,
	INSTITUTION_DETAIL,
	USER_SET_INSTITUTION,
	USER_SET_FIRST_TIME_LOGGING_IN
} from '../types'
import store from '../index'

// initial state
const state = {
	current: null,
	token: localStorage.getItem('token'),
	isLoggedIn: !!localStorage.getItem('token'),
	stripeAccounts: process.env.VUE_APP_stripeFallbackAccounts.split(','),
	firstTimeLoggingIn: false
}

const getters = {
	fullName: state => {
		return [
			get(state, 'current.first_name', ''),
			get(state, 'current.last_name', '')
		].join(' ')
	},
	isLoggedIn: state => state.isLoggedIn,
	hasUser: state => !!state.current,

	role: state => get(state, 'current.role_id', Number.MAX_VALUE),
	myInstitution: state => get(state, 'current.institution', null),
	institutionHasFinishedOnboarding: state =>
		store.getters.hasStripe === true && !!state.current,
	institutionHasFinishedBrandOnboarding: state =>
		state.hasStartedBrandOnboarding && state.hasVisitedBrandOnboarding,
	hasInstitution: state => get(state, 'current.institution', null) != null,
	isVerified: state => get(state, 'current.verified', false),
	me: state => state.current,
	firstTimeLoggingIn: state => state.firstTimeLoggingIn
}

const actions = {
	login({ commit, dispatch }, creds) {
		analytics.track('login_attempted', {
			email: creds.email
		})
		return new Promise((resolve, reject) => {
			axios
				.post('/login', creds)
				.then(({ data }) => {
					analytics.track('login', {
						email: creds.email
					})
					analytics.identify(data.id)
					commit(USER_SET_CURRENT, data)
					if (typeof window !== 'undefined') {
						var _hsq = (window._hsq = window._hsq || [])

						_hsq.push([
							'identify',
							{
								email: data.email
							}
						])
					}

					commit(INSTITUTION_DETAIL, data.institution)
					commit(LOGIN, { token: data.token })
					dispatch('getCurrentUser')
					dispatch('resetTasks')
					dispatch('getTasks')
					resolve(data)
				})
				.catch(({ response }) => {
					if (response && response.data) {
						reject(response.data)
					} else {
						reject(
							new Error(
								'Incorrect email or password combination. Please try again.'
							)
						)
					}
				})
		})
	},

	logout({ commit }) {
		analytics.track('logout')
		return new Promise((resolve, reject) => {
			commit(LOGOUT)
			resolve()
		})
	},

	getCurrentUser({ commit, state, dispatch }) {
		return new Promise((resolve, reject) => {
			axios.get('/me').then(
				({ data }) => {
					commit(USER_SET_CURRENT, data)
					commit(INSTITUTION_DETAIL, data.institution)
					resolve()
				},
				({ response }) => {
					if (get(response, 'status', 200) === 401) {
						commit('LOGOUT')
					}
					reject(response)
				}
			)
		})
	},

	checkUserLoad({ commit, state, dispatch }) {
		if (state.current) {
			return new Promise((resolve, reject) => {
				resolve()
			})
		} else {
			return dispatch('getCurrentUser')
		}
	},

	register({ commit, dispatch }, user) {
		return new Promise((resolve, reject) => {
			const analyticsData = {
				referral_code: user.referral_code,
				utm_source: user.utm_source,
				utm_medium: user.utm_medium,
				utm_campaign: user.utm_campaign,
				email: user.email
			}
			analytics.track('user_sign_up_attempted', analyticsData)

			axios
				.post('/register', user)
				.then(
					({ data }) => {
						analytics.identify(data.id)
						analytics.track('user_sign_up', analyticsData)
						commit(USER_SET_CURRENT, data)
						commit(LOGIN, { token: data.token })
						resolve(data)
					},
					({ response }) => {
						reject(response.data)
					}
				)
				.catch(() => {
					analytics.track('user_sign_up_failed', analyticsData)
				})
		})
	},

	acceptInvite(context, user) {
		return new Promise((resolve, reject) => {
			axios.post('/invite', user).then(
				({ data }) => {
					analytics.track('admin_accept_invite', {
						institution_id: data.institution_id
					})
					resolve(data)
				},
				({ response }) => {
					reject(response.data)
				}
			)
		})
	},

	forgotPassword(context, email) {
		return new Promise((resolve, reject) => {
			axios.post('/password/forgot', { email: email }).then(
				({ data }) => {
					analytics.track('user_forgot_password', {
						email: email
					})
					resolve(data)
				},
				({ response }) => {
					reject(response.data)
				}
			)
		})
	},

	resetPassword(context, creds) {
		return new Promise((resolve, reject) => {
			axios.post('/password/reset', creds).then(
				({ data }) => {
					analytics.track('user_reset_password', {
						email: creds.email
					})
					resolve(data)
				},
				({ response }) => {
					reject(response.data)
				}
			)
		})
	},

	switchInstitution({ commit, dispatch }, institution_id) {
		return new Promise((resolve, reject) => {
			axios
				.post('/me/institution', { id: institution_id })
				.then(({ data }) => {
					window.location = '#/overview'
					analytics.track('user_switch_org', {
						new_org: institution_id
					})
					commit(INSTITUTION_DETAIL, data.institution)
					commit(LOGIN, { token: data.token })
					dispatch('getCurrentUser')
					dispatch('resetTasks')
					dispatch('getTasks')
					resolve(data)
				})
		})
	}
}

// mutations
const mutations = {
	[LOGIN](state, tokens) {
		state.isLoggedIn = true
		state.token = tokens.token

		// Store token as well as set it for axios use
		localStorage.setItem('token', tokens.token)
	},

	[LOGOUT](state) {
		state.isLoggedIn = false
		state.token = null
		state.current = null

		// Remove local store
		localStorage.removeItem('token')

		window.location = '#/Login'
	},
	[USER_SET_CURRENT](state, user) {
		state.current = user

		// Vue.prototype.$intercom.boot({
		//     user_id: user.id,
		//     name: user.first_name + ' ' + user.last_name,
		//     email: user.email
		// })
	},
	[USER_SET_FIRST_TIME_LOGGING_IN](state, bool) {
		state.firstTimeLoggingIn = bool
	},

	[USER_SET_INSTITUTION](state, institution) {
		state.current.institution = institution
	},
	[USER_REMOVE_CURRENT](state, user) {
		state.current = null
	},
	[USER_SUCCESS_STEP](state, step) {
		state.current[step] = true
	}
}

export default {
	state,
	getters,
	mutations,
	actions
}
