<template>
	<section class="screen" :class="size">
		<div class="screen-header" v-if="hasTitleSlot">
			<div class="screen-title">
				<slot name="title"></slot>
				<slot name="subtitle"></slot>
			</div>
			<div class="screen-buttons">
				<slot name="buttons"></slot>
			</div>
		</div>
		<div class="screen-chart" v-if="hasChartSlot">
			<slot name="chart"></slot>
		</div>
		<div class="screen-table" v-if="hasTableSlot">
			<slot name="table"></slot>
		</div>
		<div class="screen-body" v-if="hasBodySlot">
			<slot name="body"></slot>
		</div>
		<div class="screen-footer" v-if="hasFooterSlot">
			<slot name="footer"></slot>
		</div>
		<slot></slot>
	</section>
</template>

<script>
export default {
	name: 'roundup-screen',
	props: ['size'],
	computed: {
		hasTitleSlot() {
			return !!this.$slots['title']
		},
		hasTableSlot() {
			return !!this.$slots['table']
		},
		hasChartSlot() {
			return !!this.$slots['chart']
		},
		hasBodySlot() {
			return !!this.$slots['body']
		},
		hasFooterSlot() {
			return !!this.$slots['footer']
		}
	}
}
</script>

<style lang="scss">
@import '../../styles/variables';

.screen {
	background: $white;
	margin-bottom: 20px;
	padding: 20px 0px 0px 0px;
	border-radius: 14px;
	overflow: hidden;
	overflow-x: scroll;
	width: 100%;
	min-width: 1024px;
	&.small {
		max-width: 420px;
	}
	&.medium {
		max-width: 600px;
	}
	&.medium-plus {
		max-width: 750px;
	}
	&.large {
		width: 100%;
	}
	.screen-header {
		display: flex;
		margin: -20px auto 0px -20px;
		padding: 15px 20px 0px 20px !important;
		.screen-title {
			flex-grow: 1;
			padding-left: 25px;
			h1 {
				font-family: 'Work Sans', sans-serif;
				font-size: 24px;
				font-weight: 600;
				margin-top: 5px;
				margin-bottom: 0px;
				color: $roundup-charcoal;
			}
		}
		.screen-buttons {
			flex-grow: 0;
			flex-shrink: 0;
			button {
				margin-top: 0px;
			}
		}
	}
	.screen-body {
		padding: 20px 0px 30px 0px;
	}
	.screen-table {
		position: relative;
		margin-left: -20px;
		margin-right: -20px;
		padding: 0px 40px;
		margin-top: 10px;
		&::before {
			content: '';
			position: absolute;
			width: 100%;
			height: 48px;
			top: -1px;
			left: 0px;
			background: $roundup-lightest-grey;
			border-top: 1px solid $roundup-light-grey;
			border-bottom: 1px solid $roundup-lighter-grey;
		}
	}
	.el-table__header-wrapper {
		position: relative;
	}
	.el-table--enable-row-hover {
		.el-table__body {
			tr {
				td {
					transition: none;
					cursor: pointer;
				}
				&:hover {
					border-radius: 5px;
					overflow: hidden;
					td {
						&:first-child {
							border-top-left-radius: 10px;
							border-bottom-left-radius: 10px;
						}
						&:last-child {
							border-top-right-radius: 10px;
							border-bottom-right-radius: 10px;
						}
					}
				}
			}
		}
	}
	.screen-chart {
		background: $roundup-lightest-grey;
		margin-left: -20px;
		margin-right: -20px;
		padding: 0px 20px;
		border-bottom: 1px solid $roundup-light-grey;
		min-height: 300px;
		.chart-options {
			padding: 10px 20px 0px;
		}
	}
	.screen-footer {
		margin-top: 20px;
		text-align: center;
	}
}
.screen .el-table__header-wrapper tr,
.screen .el-table__header-wrapper th {
	background: $roundup-lightest-grey;
	text-transform: uppercase;
}
.screen .el-table__header-wrapper tr .cell,
.screen .el-table__header-wrapper th .cell {
	font-size: 12px;
}
</style>
