import axios from 'axios'

axios.defaults.baseURL = process.env.VUE_APP_baseUrl
axios.defaults.headers.common = {
	'X-Requested-With': 'XMLHttpRequest',
	'X-Roundup-Type': process.env.VUE_APP_type
}

axios.interceptors.request.use(function(config) {
	if (!config.headers) {
		config.headers = {} // Create the header object if needed.
	}
	// get the authentication token from local storage if it exists
	const token = localStorage.getItem('token')
	config.headers['Authorization'] = token ? `Bearer ${token}` : null

	if (localStorage.demo && localStorage.demo !== 'false') {
		const demoRoutes = ['reports', 'donors']
		const showDemo = demoRoutes.some(rt => config.url.includes(rt))
		if (showDemo) {
			config.baseURL = config.baseURL.replace('admin', 'demo/admin')
		}
	}

	return config
})

export default axios
