import axios from '../../network/axios'
import {
	GET_MARKETING_LIBRARY,
	GET_MARKETING_LIBRARY_SUCCESS,
	GET_LIBRARY_ZIP,
	GET_LIBRARY_ZIP_SUCCESS,
	GET_LIBRARY_ZIP_ERROR,
	GET_LIBRARY_IMAGE,
	GET_LIBRARY_IMAGE_SUCCESS
} from '../types.js'

const state = {
	isFetching: false,
	imageIsFetching: false,
	zipIsFetching: false,
	images: {
		emails: [],
		example: [],
		facebookPosts: [],
		instagramPosts: [],
		textMessages: [],
		twitterPosts: []
		// websiteBanners: []
	},
	files: {
		howToGuides: [],
		promotionalHandouts: []
	},
	size: {
		grow: 0,
		launch: 0,
		support: 0
	},
	zip: '',
	categories: []
}

const getters = {
	marketingLoading: state => state.isFetching,
	marketingCategories: state => state.categories,
	marketingFiles: state => state.files,
	marketingTemplates: state => state.images,
	marketingZip: state => state.zip,
	marketingSize: state => state.size
}

// actions
const actions = {
	getLibrary({ commit }) {
		return new Promise((resolve, reject) => {
			commit(GET_MARKETING_LIBRARY)
			axios
				.get('/marketing')
				.then(response => {
					commit(GET_MARKETING_LIBRARY_SUCCESS, response.data)
					resolve()
				})
				.catch(err => console.log(err))
		})
	},
	getLibraryZip({ commit }, category) {
		return new Promise((resolve, reject) => {
			commit(GET_LIBRARY_ZIP)
			axios
				.post('/marketing/zip', { category })
				.then(response => {
					commit(GET_LIBRARY_ZIP_SUCCESS, response.data)
					resolve()
				})
				.catch(err => {
					commit(GET_LIBRARY_ZIP_ERROR, err)
					reject(err)
				})
		})
	},
	getLibraryImage({ commit }, url) {
		return new Promise((resolve, reject) => {
			commit(GET_LIBRARY_IMAGE)
			axios
				.post('/marketing/image', { url }, { responseType: 'blob' })
				.then(response => {
					commit(GET_LIBRARY_IMAGE_SUCCESS, {
						data: response.data,
						url
					})
					resolve()
				})
				.catch(err => console.log(err))
		})
	}
}

const mutations = {
	[GET_MARKETING_LIBRARY](state) {
		state.isFetching = true
	},
	[GET_MARKETING_LIBRARY_SUCCESS](state, response) {
		state.isFetching = false
		for (var file in response.files) {
			response.files[file] = response.files[file].sort((a, b) => {
				if (a.order === b.order) {
					return a.created_at - b.created_at
				} else {
					return a.order - b.order
				}
			})
		}
		for (var image in response.images) {
			response.images[image] = response.images[image].sort((a, b) => {
				if (a.order === b.order) {
					return a.created_at - b.created_at
				} else {
					return a.order - b.order
				}
			})
		}
		state.files = response.files
		state.images = response.images
		state.size = response.size
		state.categories = response.categories
	},
	[GET_LIBRARY_ZIP](state) {
		state.zipIsFetching = true
	},
	[GET_LIBRARY_ZIP_SUCCESS](state, response) {
		state.zipIsFetching = false
		state.zip = response.url
	},
	[GET_LIBRARY_ZIP_ERROR](state, err) {
		state.zipIsFetching = false
	},
	[GET_LIBRARY_IMAGE](state) {
		state.imageIsFetching = true
	},
	[GET_LIBRARY_IMAGE_SUCCESS](state, { data, url }) {
		state.imageIsFetching = false
		var fileURL = window.URL.createObjectURL(new Blob([data]))
		var fileLink = document.createElement('a')

		fileLink.href = fileURL
		fileLink.setAttribute('download', url.split('/')[3])
		document.body.appendChild(fileLink)

		fileLink.click()
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}
