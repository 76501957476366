import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'
import { pluck, getUrlParams } from '../utilities'
import { analytics } from '../utilities/tracker'
import _ from 'lodash'

Vue.use(Router)

const tableProps = params => {
	return route => {
		var props = route.query || {
			page: 1,
			perPage: 25,
			sortBy: null,
			sortDir: null
		}
		props.page = parseInt(props.page || 1)
		props.perPage = parseInt(props.perPage || 25)
		props.sortDir = props.sortDir
		props.sortBy = props.sortBy
		return Object.assign(props, pluck(route.params, params))
	}
}

const router = new Router({
	routes: [
		// {
		// 	path: '/',
		// 	redirect: to => {
		// 		console.log(store.getters)
		// 		console.log(store.getters.onboardingStep)
		// 		console.log(store.getters.role)
		// 		if (store.getters.role === 1) {
		// 			return 'Brand'
		// 		}
		// 		console.log(store.getters.onboardingStep < 3)
		// 		if (store.getters.onboardingStep < 3) {
		// 			console.log('im here')
		// 			return 'steps'
		// 		}
		// 		return 'Brand'
		// 	}
		// },
		{
			path: '/Register',
			name: 'register',
			component: () => import('@/components/pages/Auth/RegisterView'),
			meta: { guest: true }
		},
		{
			path: '/CreateOrganization',
			name: 'create-organization',
			component: () =>
				import('@/components/pages/Auth/CreateOrganizationView'),
			meta: { guest: true },
			props: true
		},
		{
			path: '/BankAccount',
			name: 'bank-account',
			component: () => import('@/components/pages/Onboarding/BankAccount')
		},
		{
			path: '/LinkedAccount',
			name: 'linked-account',
			component: () =>
				import('@/components/pages/Onboarding/LinkedAccount'),
			meta: { guest: false }
		},
		{
			path: '/overview',
			name: 'home',
			component: () => import('@/components/pages/Home')
		},
		{
			path: '/steps',
			name: 'steps',
			component: () => import('@/components/pages/Onboarding/Steps')
		},
		{
			path: '/steps/update',
			name: 'step-update',
			component: () => import('@/components/pages/Onboarding/UpdateStep')
		},
		{
			path: '/Resources',
			name: 'resources',
			component: () => import('@/components/pages/Onboarding/Resources')
		},
		{
			path: '/Resources/Library',
			name: 'library',
			component: () => import('@/components/pages/Marketing/Library')
		},
		{
			path: '/Congrats',
			name: 'congrats',
			component: () => import('@/components/pages/Auth/CongratsView'),
			meta: { guest: true }
		},
		{
			path: '/accept_TOS',
			name: 'TOS',
			component: () => import('@/components/pages/Auth/TOSView')
		},
		{
			path: '/verify',
			name: 'verify',
			component: () => import('@/components/pages/Auth/UpdateView')
		},
		{
			path: '/Login',
			name: 'login',
			component: () => import('@/components/pages/Auth/LoginView'),
			meta: { guest: true }
		},
		{
			path: '/',
			name: 'launch',
			component: () => import('@/components/pages/Auth/LaunchView'),
			meta: { guest: true }
		},
		{
			path: '/Invite/:uuid',
			name: 'invite',
			component: () => import('@/components/pages/Auth/InviteView'),
			meta: { guest: true },
			props: true
		},
		{
			path: '/ForgotPassword',
			name: 'forgot-password',
			component: () =>
				import('@/components/pages/Auth/ForgotPasswordView'),
			meta: { guest: true }
		},
		{
			path: '/ResetPassword/:uuid',
			name: 'reset-password',
			component: () =>
				import('@/components/pages/Auth/ResetPasswordView'),
			meta: { guest: true },
			props: true
		},
		{
			path: '/reset-password/:uuid',
			name: 'reset-password-alternative',
			component: () =>
				import('@/components/pages/Auth/ResetPasswordView'),
			meta: { guest: true },
			props: true
		},
		{
			path: '/Donors',
			name: 'donors',
			component: () => import('@/components/pages/Donors/DonorListView'),
			props: tableProps()
		},
		{
			path: '/Donors/Donor/:donorId',
			name: 'donors.detail',
			component: () =>
				import('@/components/pages/Donors/DonorDetailView'),
			props: tableProps(['donorId'])
		},
		{
			path: '/Donors/Merchants',
			name: 'donors.merchants',
			component: () =>
				import('@/components/pages/Merchants/MerchantListView.vue')
		},
		{
			path: '/Donors/Merchants/:donorId',
			name: 'donors.merchants.detail',
			component: () =>
				import('@/components/pages/Donors/DonorDetailView'),
			props: tableProps(['donorId'])
		},
		{
			path: '/Campaigns',
			name: 'campaigns',
			component: () =>
				import('@/components/pages/Projects/ProjectListView'),
			props: tableProps()
		},
		{
			path: '/Reports',
			name: 'reports',
			component: () => import('@/components/pages/Reports/ReportsView')
		},
		{
			path: '/Reports/NewDonors',
			name: 'reports.donors',
			component: () =>
				import('@/components/pages/Reports/NewDonorsReportView.vue'),
			props: tableProps()
		},
		{
			path: '/Reports/Donations',
			name: 'reports.donations',
			component: () =>
				import('@/components/pages/Reports/DonationsReportView.vue'),
			props: tableProps()
		},
		{
			path: '/Reports/Views',
			name: 'reports.views',
			component: () =>
				import('@/components/pages/Reports/ProfileReportView.vue'),
			props: tableProps()
		},
		{
			path: '/Profile',
			name: 'profile',
			component: () =>
				import('@/components/pages/Profile/ProfileView.vue')
		},
		{
			path: '/Brand',
			name: 'brand',
			component: () => import('@/components/pages/Profile/CustomPage.vue')
		},
		{
			path: '/Users',
			name: 'users',
			component: () =>
				import('@/components/pages/Users/UserListView.vue'),
			props: tableProps()
		},
		{
			path: '/Logout',
			name: 'logout',
			beforeEnter: (to, from, next) => {
				store.dispatch('logout').then(() => {
					next({ name: 'welcome' })
				})
			}
		},
		{
			path: '/DonationForm',
			name: 'donation-form',
			component: () => import('@/components/pages/DonationForm/index.vue')
		},
		{
			path: '/Billing',
			name: 'billing-details',
			component: () => import('@/components/pages/Profile/Receipts.vue')
		}
	]
})

var shouldSetupRoute = (to, next) => {
	const params = getUrlParams(to.fullPath)

	if (store.getters.role === 1 || params.redirect === '0') {
		return false
	}

	if (!store.getters.hasInstitution) {
		if (to.name === 'create-organization') {
			next()
			return true
		}
		next({ name: 'create-organization' })
		return true
	}
	// else if (!store.getters.hasStripe) {
	//     let uuid = store.getters.institutionUuid
	//     window.location.href = process.env.VUE_APP_stripeUrl + '&state=' + uuid
	//     return true
	// }
	// else if (!store.getters.approved) {
	//     if (to.name === 'congrats') {
	//         next()
	//         return true
	//     }
	//     next({ name: 'congrats' })
	//     return true
	// }
	else if (!store.getters.hasTOS) {
		if (to.name === 'TOS') {
			next()
			return true
		}
		next({ name: 'TOS' })
		return true
	}
	if (!store.getters.isVerified) {
		if (to.name === 'verify') {
			next()
			return true
		}
		next({ name: 'verify' })
		return true
	}

	return false
}

router.beforeEach((to, from, next) => {
	if (
		// see if there are UTM parameters
		Object.keys(from.query).length > 0 &&
		// see if they're ACTUALLY UTM PARAMETERS
		Object.keys(from.query)
			.join('')
			.includes('utm') &&
		// confirm that you are moving to a guest state
		to.meta.guest === from.meta.guest &&
		// make sure we havent already added the parameters
		!_.isEqual(from.query, to.query)
	) {
		next({ path: to.path, query: from.query })
		return
	}
	// Make sure you always let people login
	if (to.meta.guest && !store.state.me.isLoggedIn) {
		next()
		return
	}

	if (analytics) {
		window.analytics.page(to.fullPath, {
			referrer: window.location.origin + '/#' + from.path,
			url: window.location.origin + '/#' + to.fullPath
		})
	}

	// Ensure we have a user object before we start routing
	store.dispatch('checkUserLoad').then(
		() => {
			// Save previous page on every route change
			if (from.name !== 'login' && from.name !== 'welcome') {
				localStorage.setItem('prevPage', from.path)
			}

			if (to.name === 'logout') {
				next()
			}

			if (shouldSetupRoute(to, next)) {
				return
			}

			const params = getUrlParams(to.fullPath)
			// If you are trying to go to the login screen, send you to home
			if (
				to.meta.guest &&
				store.state.me.isLoggedIn &&
				params.redirect !== '0'
			) {
				// If they have selected no stripe account or have finished
				// setup then show steps

				if (to.path === '/' && store.getters.onboardingStep >= 3) {
					next({ name: 'home' })
					return
				}

				if (store.getters.hasStripe === true) {
					next({ name: 'home' })
					return
				}

				if (to.path === '/' && store.getters.onboardingStep < 3) {
					if (store.getters.role === 1) {
						next({ name: 'home' })
					} else {
						next({ name: 'home' })
					}

					return
				}
			}

			if (typeof window !== 'undefined') {
				var _hsq = (window._hsq = window._hsq || [])

				_hsq.push([
					'identify',
					{
						email: store.state.me.current.email
					}
				])
			}

			// check onboarding statuses
			if (
				!store.getters.institutionHasFinishedOnboarding &&
				store.getters.role !== 1
			) {
				if (!store.getters.hasStripe && to.name !== 'bank-account') {
					next({ name: 'bank-account' })
					return
				}
				if (store.getters.hasStripe === true && to.name !== 'brand') {
					next({ name: 'brand' })
					return
				}
			}

			if (to.name === 'library') {
				window.localStorage.setItem('hasViewedMarketingLibrary', true)
			}

			// If everything is still golden, lets keep going
			next()
		},
		() => {
			next({
				name: 'launch'
			})
		}
	)
})

export default router
