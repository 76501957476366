import axios from '../../network/axios'
import { USER_LIST, USER_LIST_REQUEST } from '../types.js'

const state = {
	isFetching: false,
	items: [],
	total: 0
}

const getters = {
	hasMultipleAdmins: state => state.total > 1
}

// actions
const actions = {
	getUsers({ commit }) {
		return new Promise((resolve, reject) => {
			commit(USER_LIST_REQUEST)
			axios
				.get('/users')
				.then(({ data }) => {
					commit(USER_LIST, data)
					resolve()
				})
				.catch(reject)
		})
	}
}

const mutations = {
	[USER_LIST_REQUEST](state) {
		state.isFetching = true
	},
	[USER_LIST](state, response) {
		state.isFetching = false
		state.items = response.results
		state.total = response.total
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}
