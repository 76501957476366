<template>
	<div class="auth-wrapper">
		<top-nav-onboard v-if="hasUser"></top-nav-onboard>
		<router-view class="router_style"></router-view>
		<onboarding-footer></onboarding-footer>
	</div>
</template>

<script>
import TopNavOnboard from './TopNavOnboarding'
import OnboardingFooter from './OnboardingFooter'
import { RoundUpAlert } from '../ui'

import { mapGetters, mapState } from 'vuex'
export default {
	name: 'layout',
	components: { TopNavOnboard, OnboardingFooter, RoundUpAlert },
	computed: {
		...mapState({
			isLoggedIn: ({ me }) => me.isLoggedIn
		}),
		...mapGetters([
			'hasUser',
			'approved',
			'role',
			'institutionHasFinishedOnboarding'
		])
	}
}
</script>

<style lang="scss">
@import '~@roundupapp/component-library/src/styles/colors';

.auth-wrapper {
	background: $roundup-bone;
	width: 100%;
	min-height: 100vh;
	.router_style {
		margin-top: 100px !important;
	}
}
</style>
