import axios from '../../network/axios'

import {
	GET_TASK_LIST,
	GET_TASK_LIST_SUCCESS,
	GET_TASK_LIST_ERROR
} from '../types'

const tasks = [
	{
		name: 'Invite Additional Admins',
		property: ['hasMultipleAdmins'],
		description: 'We recommend having 2-3 admins on your RoundUp account.',
		cta: 'Invite Admins',
		ctaLink: {
			name: 'profile',
			query: { tab: 1 }
		},
		id: 1
	},
	{
		name: 'Customize Brand Page',
		property: ['hasCoverImage', 'hasLogo'],
		description:
			'Customizing your brand page can increase sign ups by 33%!',
		cta: 'Customize Now',
		ctaLink: {
			name: 'brand'
		},
		id: 2
	},
	{
		name: 'Set Active Donor Goal',
		property: ['hasDonorGoal'],
		description:
			'Setting a goal increases your chances of achieving it by 42%!',
		cta: 'Set a Goal',
		ctaLink: {
			name: 'home',
			query: { goal: true }
		},
		id: 3
	},
	{
		name: 'Visit Your Marketing Library',
		property: ['hasViewedMarketingLibrary'],
		description:
			'We have dozens of templates to help you discover and source new donors!',
		cta: 'View Resources',
		ctaLink: {
			name: 'library'
		},
		id: 4
	}
	// {
	// 	name: 'Add RoundUp App to you website',
	// 	description:
	// 		'Setting a goal increases your changes of achieving by 42%!',
	// 	cta: 'View my site',
	// 	property: [true],
	// 	ctaLink: {
	// 		name: 'home',
	// 		query: { goal: true }
	// 	}
	// },
	// {
	// 	name: 'Add RoundUp App to you website',
	// 	description:
	// 		'Setting a goal increases your changes of achieving by 42%!',
	// 	cta: 'View my site',
	// 	property: [true],
	// 	ctaLink: {
	// 		name: 'home',
	// 		query: { goal: true }
	// 	}
	// }
]

const state = {
	items: [],
	loading: false
}

const getters = {
	taskList: state => state.items,
	hasViewedMarketingLibrary: () =>
		!!JSON.parse(window.localStorage.getItem('hasViewedMarketingLibrary'))
}

const actions = {
	getTasks({ commit, rootGetters }) {
		commit(GET_TASK_LIST)

		return new Promise((resolve, reject) => {
			axios
				.get('/tasks')
				.then(({ data }) => {
					let incompleteTasks = []

					data.results.forEach(task => {
						let isComplete = JSON.parse(task.success_flag).every(
							prop => rootGetters[prop]
						)
						let dismissed = localStorage.dismissedList || '[]'
						dismissed = JSON.parse(dismissed)

						if (!isComplete && !dismissed.includes(task.id)) {
							incompleteTasks.push(task)
						}
					})

					commit(GET_TASK_LIST_SUCCESS, incompleteTasks)
					resolve()
				})
				.catch(reject)
		})
	},
	resetTasks({}) {
		localStorage.dismissedList = '[]'
	}
}

const mutations = {
	[GET_TASK_LIST](state) {
		state.loading = true
	},
	[GET_TASK_LIST_SUCCESS](state, items) {
		state.items = items
		state.loading = false
	},
	[GET_TASK_LIST_ERROR](state, error) {
		state.loading = false
		state.error = error
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}
