import axios from '../../network/axios'
import { GET_TEMPLATES, GET_TEMPLATES_SUCCESS } from '../types.js'

const state = {
	isFetching: false,
	items: []
}

const getters = {}

// actions
const actions = {
	getTemplates({ commit }, params) {
		return new Promise((resolve, reject) => {
			commit(GET_TEMPLATES)
			axios
				.get('/notify', { params })
				.then(response => {
					commit(GET_TEMPLATES_SUCCESS, response)
					resolve(response.data)
				})
				.catch(reject)
		})
	},

	updateTemplates({ commit }, templates) {
		return new Promise((resolve, reject) => {
			axios
				.post('/notify', { templates })
				.then(response => {
					commit(GET_TEMPLATES_SUCCESS, response)
					resolve()
				})
				.catch(reject)
		})
	},

	sendSMS(
		{ commit },
		{ userId, admin, message, phone = null, test = false }
	) {
		return new Promise((resolve, reject) => {
			axios
				.post('/notify/sms', {
					user_id: userId,
					admin,
					message,
					phone,
					test
				})
				.then(mid => {
					resolve(mid)
				})
				.catch(reject)
		})
	}
}

const mutations = {
	[GET_TEMPLATES](state) {
		state.isFetching = true
	},
	[GET_TEMPLATES_SUCCESS](state, response) {
		state.isFetching = false
		state.items = response.data
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}
