import Vue from 'vue'
import Vuex from 'vuex'

import me from './modules/me'
import donorDetail from './modules/donor.detail'
import donorList from './modules/donor.list'
import institution from './modules/institution'
import institutionList from './modules/institution.list'
import institutionSearchDetail from './modules/institution.list'
import stripeActions from './modules/stripe.actions'
import reportList from './modules/report.list'
import userDetail from './modules/user.detail'
import userList from './modules/user.list'
import projectList from './modules/project'
import projectDetail from './modules/project.detail'
import notify from './modules/notify'
import marketing from './modules/marketing'
import merchantList from './modules/merchant.list'
import taskList from './modules/task.list'
import embedDetail from './modules/embed.detail'
import alert from './modules/alert'
import receiptList from './modules/receipt.list'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		me,
		alert,
		donorDetail,
		donorList,
		institution,
		institutionList,
		institutionSearchDetail,
		reportList,
		userDetail,
		userList,
		projectList,
		projectDetail,
		notify,
		marketing,
		merchantList,
		stripeActions,
		taskList,
		embedDetail,
		receiptList
	},
	strict: true
})
