/* eslint no-multi-spaces: ["error", { exceptions: { "VariableDeclarator": true } }] */

export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const SET_HEADERS = 'SET_HEADERS'

export const USER_SET_CURRENT = 'USER_SET_CURRENT'
export const USER_REMOVE_CURRENT = 'USER_REMOVE_CURRENT'
export const USER_ACCOUNTS = 'USER_ACCOUNTS'
export const USER_SUCCESS_STEP = 'USER_SUCCESS_STEP'
export const USER_SET_INSTITUTION = 'USER_SET_INSTITUTION'

export const DONOR_LIST = 'DONOR_LIST'
export const DONOR_LIST_REQUEST = 'DONOR_LIST_REQUEST'
export const DONOR_DETAIL = 'DONOR_DETAIL'

export const MERCHANT_LIST = 'MERCHANT_LIST'
export const MERCHANT_LIST_REQUEST = 'MERCHANT_LIST_REQUEST'

export const INSTITUTION_LIST = 'INSTITUTION_LIST'
export const INSTITUTION_LIST_REQUEST = 'INSTITUTION_LIST_REQUEST'
export const INSTITUTION_DETAIL = 'INSTITUTION_DETAIL'
export const INSTITUTION_DETAIL_REQUEST = 'INSTITUTION_DETAIL_REQUEST'
export const INSTITUTION_SEARCH = 'INSTITUTION_SEARCH'
export const INSTITUTION_SEARCH_DETAIL = 'INSTITUTION_SEARCH_DETAIL'
export const CATEGORY_LIST = 'CATEGORY_LIST'
export const SUBCATEGORY_LIST = 'SUBCATEGORY_LIST'
export const SUBCATEGORY_SEARCH = 'SUBCATEGORY_SEARCH'

export const REPORT_LIST = 'REPORT_LIST'
export const REPORT_LIST_REQUEST = 'REPORT_LIST_REQUEST'
export const REPORT_TYPE_SET = 'REPORT_TYPE_SET'
export const REPORT_GENERAL = 'REPORT_GENERAL'
export const REPORT_ROI_SUCCESS = 'REPORT_ROI_SUCCESS'
export const REPORT_ROI_FETCHING = 'REPORT_ROI_FETCHING'
export const REPORT_ROI_FAIL = 'REPORT_ROI_FAIL'

export const USER_LIST = 'USER_LIST'
export const USER_LIST_REQUEST = 'USER_LIST_REQUEST'
export const USER_DETAIL = 'USER_DETAIL'
export const DONOR_DETAIL_FETCHING = 'DONOR_DETAIL_FETCHING'
export const DONOR_DETAIL_SUCCESS = 'DONOR_DETAIL_SUCCESS'

export const GET_PROJECT_REQUEST = 'GET_PROJECT_REQUEST'
export const GET_PROJECT_LIST = 'GET_PROJECT_LIST'
export const ARCHIVE_PROJECT = 'ARCHIVE_PROJECT'
export const UPDATE_PROJCET = 'UPDATE_PROJCET'
export const PROJECT_DETAIL = 'PROJECT_DETAIL'

export const GET_TEMPLATES = 'GET_TEMPLATES'
export const GET_TEMPLATES_SUCCESS = 'GET_TEMPLATE_SUCCESS'

export const GET_MARKETING_LIBRARY = 'GET_MARKETING_LIBRARY'
export const GET_MARKETING_LIBRARY_SUCCESS = 'GET_MARKETING_LIBRARY_SUCCESS'

export const STRIPE_EXPRESS_REQUEST = 'STRIPE_EXPRESS_REQUEST'
export const STRIPE_EXPRESS_SUCCESS = 'STRIPE_EXPRESS_SUCCESS'

export const STRIPE_ACCOUNT_SETUP_REQUEST = 'STRIPE_ACCOUNT_SETUP_REQUEST'
export const STRIPE_ACCOUNT_SETUP_SUCCESS = 'STRIPE_ACCOUNT_SETUP_SUCCESS'
export const STRIPE_ACCOUNT_SETUP_FAILURE = 'STRIPE_ACCOUNT_SETUP_FAILURE'

export const GET_LIBRARY_ZIP = 'GET_LIBRARY_ZIP'
export const GET_LIBRARY_ZIP_SUCCESS = 'GET_LIBRARY_ZIP_SUCCESS'
export const GET_LIBRARY_ZIP_ERROR = 'GET_LIBRARY_ZIP_ERROR'

export const GET_LIBRARY_IMAGE = 'GET_LIBRARY_IMAGE'
export const GET_LIBRARY_IMAGE_SUCCESS = 'GET_LIBRARY_IMAGE_SUCCESS'

export const GET_TASK_LIST = 'GET_TASK_LIST'
export const GET_TASK_LIST_SUCCESS = 'GET_TASK_LIST_SUCCESS'
export const GET_TASK_LIST_ERROR = 'GET_TASK_LIST_ERROR'

export const USER_SET_FIRST_TIME_LOGGING_IN = 'USER_SET_FIRST_TIME_LOGGING_IN'

export const EMBED_DETAIL = 'EMBED_DETAIL'

export const RECEIPT_LIST_REQUEST = 'RECEIPT_LIST_REQUEST'
export const RECEIPT_LIST_SUCCESS = 'RECEIPT_LIST_SUCCESS'
export const RECEIPT_LIST_FAILED = 'RECEIPT_LIST_FAILED'

// Alert

export const SET_ALERT_TIMEOUT = 'SET_ALERT_TIMEOUT'
export const SET_SHOW_ALERT = 'SET_SHOW_ALERT'
export const SET_ALERT_COLOR = 'SET_ALERT_COLOR'
export const SET_ALERT_POSITION = 'SET_ALERT_POSITION'
export const SET_ALERT_TEXT = 'SET_ALERT_TEXT'
export const RESET_ALERT = 'RESET_ALERT'
export const SET_ALERT_COUNT = 'SET_ALERT_COUNT'
