<template>
	<section class="top-nav-onboarding">
		<div>
			<div class="roundup-logo"></div>
		</div>
		<div>
			<Roundup-button
				@click="logout"
				color="white"
				v-bind:style="{
					position: 'absolute',
					top: '18px',
					right: '55px'
				}"
				>Logout</Roundup-button
			>
		</div>
	</section>
</template>

<script>
import { mapActions } from 'vuex'
import UiButton from '../ui/Button'
import UiInput from '../ui/Input'
import UiLabel from '../ui/Label'
import Screen from '../ui/Screen'
import Modal from '../ui/Modal'

export default {
	name: 'top-nav-onboard',
	components: { UiButton, UiInput, UiLabel, Screen, Modal },
	methods: {
		...mapActions(['logout'])
	}
}
</script>

<style lang="scss">
@import '../../styles/variables';

.top-nav-onboarding {
	z-index: 100;
	box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
	background: white !important;
	width: 100%;
	position: fixed;
	top: 0;
	height: 80px;
	left: 0;
	margin-bottom: 50px;
	.roundup-logo {
		position: relative;
		margin-top: 25px;
		margin-left: auto;
		margin-right: auto;
		background: url('../../assets/roundup_logo.svg');
		background-size: 226px 29px;
		background-repeat: no-repeat;
		background-position: center;
		width: 226px;
		height: 29px;
		opacity: 1;
	}
}
</style>
