import axios from '../../network/axios'
import { get } from '../../utilities'
import { analytics } from '../../utilities/tracker'

import {
	INSTITUTION_DETAIL,
	INSTITUTION_DETAIL_REQUEST,
	CATEGORY_LIST,
	SUBCATEGORY_LIST,
	SUBCATEGORY_SEARCH
} from '../types'

const state = {
	isFetching: false,
	item: null,
	registerList: [],
	categories: [],
	subcategories: [],
	subcategorySearch: null
}

const getters = {
	categories: state => {
		return {
			categories: state.categories,
			subcategories: state.subcategories
		}
	},
	institutionUuid: state => get(state, 'item.uuid', null),
	approved: state => get(state, 'item.approved', false),
	hasStripe: state => get(state, 'item.stripe_setup', false),
	stripeAccount: state => get(state, 'item.stripe_account', null),
	hasTOS: state => get(state, 'item.tos_accept', false),
	hasImages: state => {
		const logo = get(state, 'item.logo_url')
		return (
			logo &&
			!logo.includes('roundupapp.com') &&
			!logo.includes('https://d2gbgm7n6hyv3d.cloudfront.net/defaults/')
		)
	},
	hasDonorGoal: state => true && state.item.donor_goal,
	hasLogo: () => {
		const logo = get(state, 'item.logo_url')
		return (
			logo &&
			!logo.includes('roundupapp.com') &&
			!logo.includes('https://d2gbgm7n6hyv3d.cloudfront.net/defaults/')
		)
	},
	hasCoverImage: state => {
		const logo = get(state, 'item.image_url')
		return (
			logo &&
			!logo.includes('roundupapp.com') &&
			!logo.includes('https://d2gbgm7n6hyv3d.cloudfront.net/defaults/')
		)
	},
	onWebsite: state => get(state, 'item.on_website', false),
	hasBrand: state => {
		const logo = get(state, 'item.logo_url')
		const cover = get(state, 'item.image_url')
		const hasLogo =
			logo &&
			!logo.includes('roundupapp.com') &&
			!logo.includes('https://d2gbgm7n6hyv3d.cloudfront.net/defaults/')
		const hasCover =
			cover &&
			!cover.includes('roundupapp.com') &&
			!cover.includes('https://d2gbgm7n6hyv3d.cloudfront.net/defaults/')
		const description = get(state, 'item.description')
		return hasLogo && hasCover && description
	},
	isOnboarding: state => {
		const logo = get(state, 'item.logo_url')
		const cover = get(state, 'item.image_url')
		const stripe = get(state, 'item.stripe_setup', false)
		return !(
			logo &&
			!logo.includes('roundupapp.com') &&
			cover &&
			!cover.includes('roundupapp.com') &&
			stripe
		)
	},
	launchDate: state => get(state, 'item.launch_date', null),
	scheduledCall: state => get(state, 'item.scheduled_call', false),
	onboardingStep: state => {
		const logo = get(state, 'item.logo_url')
		const cover = get(state, 'item.image_url')
		const hasLogo = logo && !logo.includes('roundupapp.com')
		const hasCover = cover && !cover.includes('roundupapp.com')
		const brand = hasLogo && hasCover
		const stripe = get(state, 'item.stripe_setup', false) ? 1 : 0
		const launch = get(state, 'item.launch_date', null) ? 1 : 0
		return brand + stripe + launch
	},
	donorGoal: state => {
		return get(state, 'item.donorGoal', 100) // TODO make this part of institution state
	},
	institution: state => state.item
}

// actions
const actions = {
	getInstitutionDetail({ commit, dispatch }) {
		return new Promise((resolve, reject) => {
			commit(INSTITUTION_DETAIL_REQUEST)
			axios
				.get('/institution')
				.then(({ data }) => {
					commit(INSTITUTION_DETAIL, data)
					dispatch('getTasks')
					resolve()
				})
				.catch(reject)
		})
	},
	getInstitution({ commit }) {
		return new Promise((resolve, reject) => {
			commit(INSTITUTION_DETAIL_REQUEST)
			axios
				.get('/institutions/')
				.then(({ data }) => {
					commit(INSTITUTION_DETAIL, data)
					resolve()
				})
				.catch(reject)
		})
	},
	createInstitution({ commit, dispatch }, institution) {
		return new Promise((resolve, reject) => {
			axios
				.post('/institutions', institution)
				.then(({ data }) => {
					analytics.track('org_created_or_claimed', {
						institution_id: institution.id,
						institution_name: institution.name,
						referral_code: institution.referral_code,
						utm_source: institution.utm_source,
						utm_medium: institution.utm_medium,
						utm_campaign: institution.utm_campaign
					})
					dispatch('resetTasks')
					commit(INSTITUTION_DETAIL, data)
					dispatch('getCurrentUser')
					window.location = window.location.origin
					resolve(data)
				})
				.catch(reject)
		})
	},
	updateInstitution({ dispatch }, institution) {
		return new Promise((resolve, reject) => {
			axios
				.put('/institutions', institution)
				.then(async () => {
					analytics.track('org_updated')
					await dispatch('getCurrentUser', true, { root: true })
					resolve()
				})
				.catch(err => {
					reject(err)
				})
		})
	},
	updateInstitutionUsername({ dispatch }, username) {
		return new Promise((resolve, reject) => {
			axios
				.put('/institution/username', username)
				.then(async () => {
					analytics.track('org_username_updated')
					await dispatch('getCurrentUser', true, { root: true })
					resolve()
				})
				.catch(e => {
					reject(e.response.data.message)
				})
		})
	},
	uploadLogo({ commit }, image) {
		return new Promise((resolve, reject) => {
			axios
				.post('/institutions/v2/upload-logo', { url: image })
				.then(({ data }) => {
					analytics.track('org_logo_upload')
					commit(INSTITUTION_DETAIL, data)
					resolve(data.logo_url)
				}, reject)
		})
	},
	uploadCover({ commit }, image) {
		return new Promise((resolve, reject) => {
			axios
				.post('/institutions/v2/upload-cover', { url: image })
				.then(({ data }) => {
					analytics.track('org_cover_upload')
					commit(INSTITUTION_DETAIL, data)
					resolve(data.image_url)
				}, reject)
		})
	},
	updateTOS({ commit }, body) {
		return new Promise((resolve, reject) => {
			axios
				.put('/institutions/tos', body)
				.then(({ data }) => {
					commit(INSTITUTION_DETAIL, data)
					resolve()
				})
				.catch(reject)
		})
	},
	getCategories({ commit }) {
		return new Promise((resolve, reject) => {
			axios
				.get(`${process.env.VUE_APP_rootUrl}/institutions/categories`)
				.then(({ data }) => {
					data.sort((a, b) => {
						if (a.title > b.title) return 1
						if (a.title < b.title) return -1
						return 0
					})
					commit(CATEGORY_LIST, data)
					resolve()
				})
				.catch(reject)
		})
	},
	getSubCategories({ commit }, id) {
		return new Promise((resolve, reject) => {
			axios
				.get(
					`${process.env.VUE_APP_rootUrl}/institutions/subcategories/${id}`
				)
				.then(({ data }) => {
					data.slice(1)
					data.sort((a, b) => {
						if (a.title > b.title) return 1
						if (a.title < b.title) return -1
						return 0
					})
					commit(SUBCATEGORY_LIST, data)
					resolve()
				})
				.catch(reject)
		})
	},
	getSubCategoryList({ commit }) {
		return new Promise((resolve, reject) => {
			axios
				.get(
					`${process.env.VUE_APP_rootUrl}/institutions/subcategories`
				)
				.then(({ data }) => {
					data.slice(1)
					data.sort((a, b) => {
						if (a.title > b.title) return 1
						if (a.title < b.title) return -1
						return 0
					})
					commit(SUBCATEGORY_LIST, data)
					resolve()
				})
				.catch(reject)
		})
	},
	searchNteeCode({ commit }, code) {
		return new Promise((resolve, reject) => {
			axios
				.get(
					`${process.env.VUE_APP_rootUrl}/institutions/subcategories/search/${code}`
				)
				.then(({ data }) => {
					commit(SUBCATEGORY_SEARCH, data)
					resolve()
				})
				.catch(reject)
		})
	}
}

const mutations = {
	[INSTITUTION_DETAIL_REQUEST](state) {
		state.isFetching = true
	},
	[INSTITUTION_DETAIL](state, institution) {
		state.isFetching = false
		// don't ask...
		if (institution && institution.address) {
			institution.address.google = {}
		}
		state.item = institution
	},
	[CATEGORY_LIST](state, list) {
		state.categories = list
	},
	[SUBCATEGORY_LIST](state, list) {
		state.subcategories = list
	},
	[SUBCATEGORY_SEARCH](state, data) {
		state.subcategorySearch = data
	}
}

export default {
	state,
	actions,
	getters,
	mutations
}
