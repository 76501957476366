import axios from '../../network/axios'
import { GET_PROJECT_REQUEST, GET_PROJECT_LIST } from '../types'

const state = {
	isFetching: false,
	items: []
}

const getters = {}

// actions
const actions = {
	getProjects({ commit }) {
		return new Promise((resolve, reject) => {
			commit(GET_PROJECT_REQUEST)
			axios
				.get('/projects')
				.then(({ data }) => {
					commit(GET_PROJECT_LIST, data)
					resolve()
				})
				.catch(reject)
		})
	}
}

const mutations = {
	[GET_PROJECT_REQUEST](state) {
		state.isFetching = true
	},
	[GET_PROJECT_LIST](state, response) {
		state.isFetching = false
		state.items = response
	}
}

export default {
	state,
	actions,
	getters,
	mutations
}
