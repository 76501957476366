<template>
	<button
		:disabled="disabled"
		class="roundup-button"
		:type="nativeType || 'button'"
		:class="[color, type, { loading: loading }]"
		@click="$emit('click', this)"
	>
		<slot />
	</button>
</template>

<script>
export default {
	name: 'roundup-button',
	props: ['color', 'nativeType', 'loading', 'type', 'disabled']
}
</script>

<style lang="scss">
@import '~@roundupapp/component-library/src/styles/colors';

.roundup-button {
	width: auto;
	background: #184fd5;
	color: #fff !important;
	border-radius: 20px;
	cursor: pointer;
	transition: transform 0.25s;
	display: inline-block;
	font-family: objektiv-mk3, sans-serif;
	font-size: 14px;
	font-weight: 600;
	padding: 10px 20px;
	outline: none;
	margin-top: 20px;
	position: relative;
	text-decoration: none;
	border: 1px solid transparent;
	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		width: 100%;
		height: 100%;
		border-radius: 20px;
		box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
		opacity: 0;
		transition: opacity 0.3s ease-in-out;
	}
	&:hover {
		&:enabled {
			transform: translateY(-2px);
			&::after {
				opacity: 1;
			}
		}
	}
	&.primary {
		background: $roundup-navy;
		color: #fff;
	}
	&.secondary {
		background: $roundup-light-grey;
		color: #000;
		&:hover {
			background: #92979e;
			color: #fff;
		}
	}
	&.secondary-alt {
		background: $roundup-light-grey;
		color: #000;
		&:hover {
			background: #92979e;
			color: #fff;
		}
	}
	&.white {
		background: #fff;
		color: $roundup-navy;
	}
	&.danger {
		background: $roundup-red;
		color: #fff;
	}
	&:disabled {
		background: $roundup-medium-grey;
		cursor: default;
	}
	&.loading {
		padding-right: 55px;
		&:before {
			content: '';
			position: absolute;
			display: block;
			width: 0px;
			height: 0px;
			border-radius: 50%;
			right: 15px;
			top: 50%;
			border: 2px solid rgba(255, 255, 255, 0.8);
			border-right: 2px solid $roundup-navy;
			animation: rotate360 0.5s infinite linear, exist 0.1s forwards ease;
		}
	}
	&.append {
		border-radius: 0px 20px 20px 0px;
	}
}

@keyframes rotate360 {
	100% {
		transform: rotate(360deg);
	}
}

@keyframes exist {
	100% {
		width: 12px;
		height: 12px;
		margin: -8px 5px 0 0;
	}
}
</style>
